import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import MyLoadingComponent from './components/myloadcomponent'
import './App.css';
import SpeedChart from './pages/admin/trip/speedchart';

const Login = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/login')
})

const Backend=Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/backend')
})

const VehicleDetailPrint = Loadable({
  loading: MyLoadingComponent,
  loader: () => import('./pages/admin/vehicles/detailprint')
})

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/admin" component={Backend} />
      <Route path="/test" component={SpeedChart} />
      <Route path='/vehicleprint' component={VehicleDetailPrint}/>
      <Redirect from="/" to="/login" />
    </Switch>
  );
}
export default withRouter(App);
