import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class GeoFence {
    @observable PlateNos = []

    //选中的车牌
    @observable selectedPlateNos = []
    // 数据
    @observable dataSource = []

    @observable selectedGeoFences = []

    // 单个实例
    @observable model = {
        id: 0,
        selected: false,
        uuid: '',
        phoneno: [],
        description: '',
        details: '',
        almtype: 0,
        distype: 0,
        exptime: '',
        fencetype: 0,
        descriptionStatus: null,
        area: null
    }

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0,
        name: ''
    }


    @action setData(list, model, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }

        if (model !== undefined && model !== null) {
            var _model = Object.assign({}, this.model, model);
            this.model = _model;
        }

        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action initModel = () => {
        this.model = {
            id: 0,
            selected: false,
            uuid: '',
            phoneno: [],
            description: '',
            details: '',
            almtype: 0,
            distype: 0,
            exptime: '',
            fencetype: 0,
            descriptionStatus: null,
            area: null
        }
    }

    @action setItemSelected = (item, onlySelect) => {
        let addOrRemove = false;
        let _index = this.selectedGeoFences.findIndex(x => x.id === item.id);
        if (_index !== -1) {
            addOrRemove = false;
            if (onlySelect !== undefined && onlySelect) {
                addOrRemove = true;
            } else {
                var old = this.selectedGeoFences.slice();
                old.splice(_index, 1);
                this.selectedGeoFences = old;
            }
        } else {
            this.selectedGeoFences.push(item)
            addOrRemove = true;
        }
        let _index2 = this.dataSource.findIndex(x => x.id === item.id);
        if (_index2 !== -1) {
            var old2 = this.dataSource.slice();
            old2[_index2].selected = addOrRemove;
            this.dataSource = old2;
        }
    }

    @action setItemSelectedOnlyOneSelected = (item) => {
        let _index = this.dataSource.findIndex(x => x.id === item.id);
        if (_index !== -1) {
            var old = this.dataSource.slice();
            old.forEach(row => {
                if (row.id === item.id) {
                    row.selected = true;
                } else {
                    row.selected = false;
                }
            })
            this.dataSource = old;
        }
    }

    @action SetPlateNos = (data) => {
        this.PlateNos = data;
        let selecteds = [];
        var selectedItems = data.filter(x => x.selected);
        selectedItems.forEach(item => {
            selecteds.push(item.id.toString());
        })
        this.SaveSelectedPlateNos(selecteds)
    }

    @action SaveSelectedPlateNos = (data) => {
        this.selectedPlateNos = data;
    }

    @action initAllGeoFence = () => {
        this.selectedGeoFences = [];
    }
}
export default new GeoFence();