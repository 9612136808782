const zh_CN={
    'login.log_in':'登录',
    'login.register':'注册',
    'login.language_zh':'中文',
    'login.language_en':'English',
    'login.log_in_now':'登录',
    'login.log_in_process':'正在登录...',
    'login.forgot_pass':'忘记密码',
    'login.user_name':'用户名',
    'login.placeholder_username':'请输入您的账户',
    'login.placeholder_password':'请输入您的密码',
    'login.placeholder_email':'请输入您的邮箱',
    'login.back_login':'返回登录',
    'login.get_back_password':'找回密码',
    'login.send_email':'发送',
    'login.sending_email':'正在发送...',
    'login.validation_email_required':'请输入您的邮箱',
    'login.validation_email_notvalid':'邮箱不存在，请重新输入',
    'login.find_email_success':'密码重置邮件已经发送，请等待管理员处理',
    'login.find_password_faild':'密码重置失败',
    'login.login_other':'更换账户',
    'layout.header_welcome':'欢迎您',
    'layout.header_personal_info':'设置',
    'layout.header_logout':'退出',
    'layout.header_construction':'建设中',

    'layout.back_to_location':'',

    'dashboard.summary_onload':'行驶中',
    'dashboard.summary_stopped':'停驶中',
    'dashboard.summary_total_drived':'累计里程',
    'dashboard.summary_total_hours':'累计时间',
    'dashboard.summary_exception_device':'断链',
    'dashboard.summary_urgent_event':'紧急事件',
    'dashboard.report_total_devices':'行驶/停驶',
    'dashboard.report_car_total_drived':'一周里程',
    'dashboard.report_car_total_hours':'一周时长',
    'dashboard.report_mileage':'里程数',
    'dashboard.report_drving_time':'时长',
    'dashboard.stop_driving':'停驶',
    'dashboard.is_driving':'行驶',
    'userlist.list_title':'用户管理',
    'userlist.list_search_placeholder':'根据姓名搜索',
    'userlist.list_field_username':'姓名',
    'userlist.list_field_sex':'性别',
    'userlist.list_field_email':'邮箱',
    'userlist.list_field_phone':'联系电话',
    'userlist.list_field_company':'公司',
    'userlist.list_field_birthday':'生日',
    'userlist.list_field_department':'所属部门',
    'userlist.list_field_role':'角色',
    'userlist.list_field_inactive':'启用中',
    'userlist.list_field_lastlogin':'最后登录时间',
    'userlist.delete_confirm':'您确定要删除此用户吗？',
    'commonlist.location':'位置',
    'commonlist.action':'操作',
    'commonlist.add':'新增',
    'commonlist.delete':'删除',
    'commonlist.download':'导出',
    'commonlist.edit':'编辑',
    'commonlist.print':'打印',
    'commonlist.viewdetail':'查看详情',
    'commonlist.detail':'详情',
    'commonlist.search':'查询',
    'commonlist.yes':'是',
    'commonlist.no':'否',
    'commonlist.empty':'无',
    'commonlist.select':'请选择',
    'commonlist.ok':'确定',
    'commonlist.cancel':'取消',
    'commonlist.failed':'操作失败',
    'commonlist.success':'操作成功',
    'commonlist.required':'此字段必需',
    'commonlist.notify':'提示',
    'commonlist.pagenation':'{0}-{1}条 共{2}条',
    'commonlist.man':'男',
    'commonlist.woman':'女',
    'commonlist.resetpass':'重置密码',
    'commonlist.trip':'行程',
    'commonlist.change':'更换',
    'commonlist.fire':'解除',
    'commonlist.bind':'绑定',
    'commonlist.show':'显示',
    'commonlist.combine':'关联',
    'commonlist.dragmap':'点击后可以拖拽地图',
    'commonlist.drawcircle':'点击后设置圆形区域',
    'commonlist.drawpolygon':'点击后设置多边形区域',
    'commonlist.zhongbiaohistory':'中标日志',
    'commonlist.history':'操作日志',
    'commonlist.newmessage':'新消息',
    'commonlist.rememberpassword':'记住我',
    'commonlist.autologin':'自动登录',
    'commonlist.bgcolor':'背景色: ',
    'commonlist.actived':'激活 ',
    'commonlist.expired':'已过期 ',
    'commonlist.neareexpired':'即将过期 ',
    'commonlist.view':'查看结果',
    'commonlist.reset':'清除',
    'commonlist.reboot':'重启',
    'commonlist.paramsetting':'参数设置',

    'common.norecords':'暂无数据',
    'common.home':'首页',
    'common.message':'消息',
    'common.back':'返回',
    'common.header':'头像',
    'common.googlemap':'谷歌地图',
    'common.baidumap':'百度地图',
    'common.gaodemap':'高德地图',
    'common.showmore':'查看更多',
    'departmentlist.list_title':'部门管理',
    'departmentlist.list_field_code':'部门编码',
    'departmentlist.list_field_name':'部门名称',
    'departmentlist.list_field_parent_level':'上级部门',
    'departmentlist.list_field_desc':'部门描述',
    'departmentlist.delete_confirm':'您确定要删除此部门吗？',
    'profile.field.username':'姓名',
    'profile.field.sex':'性别',
    'profile.field.email':'邮箱',
    'profile.field.phone':'电话',
    'profile.field.brithday':'生日',
    'profile.field.company':'公司',
    'profile.field.avatar':'头像',
    'profile.field.personal.info':'设置',
    'profile.field.password':'密码',
    'register.field.username.required':'请填写姓名',
    'register.field.username.special.character':"请不要输入特殊字符",
    'register.field.email.required':'请填写邮箱',
    'register.field.password.required':'请输入密码',
    'register.field.company.required':'请公司名称',
    'register.field.resetPassword.required':'请再次输入密码',
    'register.field.resetPassword.notmatch':'对不起，您两次输入的密码不一致',
    'register.field.agreeProtocal.required':'您还未接受用户协议',
    'register.field.username':'姓名',
    'register.field.email':'邮箱',
    'register.field.birthday':'生日',
    'register.field.phone':'联系电话',
    'register.field.company':'公司名称',
    'register.field.password':'密码',
    'register.field.resetPassword':'确认密码',
    'register.button.register':'提交',
    'register.button.register.processing':'提交中...',
    'register.title':'注册',
    'register.userprotocal.accept':'我已阅读并同意',
    'register.userprotocal':'隐私政策',
    'register.field.email.existed':'子邮箱已存在',
    'register.field.password.existed':'您的密码不正确',
    'register.register.success':'注册成功，请等待审核。',

    'vehiclelist.longitude':'经度',
    'vehiclelist.latitude':'纬度',
    'vehiclelist.list_detail_title':'车辆详情',
    'vehiclelist.list_field_plateNo':'车牌号',
    'vehiclelist.list_field_idNo':'车辆识别码',
    'vehiclelist.list_field_engineNo':'发动机号',
    'vehiclelist.list_field_cate':'使用分类',
    'vehiclelist.list_field_brand':'车辆品牌',
    'vehiclelist.list_field_model':'车辆型号',
    'vehiclelist.list_field_purchaseDate':'购买日期（YYYY-MM-DD）',
    'vehiclelist.list_field_purchaseDate_placeholder':'购买日期',
    'vehiclelist.list_field_name':'司机姓名',
    'vehiclelist.list_field_phoneNo':'联系电话',
    'vehiclelist.list_field_agentID':'设备ID',
    'vehiclelist.list_title':'车辆列表',
    'vehiclelist.list_search_placeholder':'根据设备ID搜索',
    'vehiclelist.unlinkdevice':'您确定要解除绑定设备吗？',
    'vehiclelist.device.required':'请选择设备',
    'vehiclelist.delete_confirm':'您确定要删除此车辆吗？',
    'vehiclelist.plateNo.required':'请输入车牌号',
    'vehiclelist.purchaseDate.required':'请输入购买日期',
    'vehiclelist.column.name.plateNo':'车牌：',
    'vehiclelist.column.name.agentID':'设备ID：',
    'vehiclelist.list_field_geofence':'相关围栏',
    'vehiclelist.nolocation':'没有位置信息',
    'vehiclelist.drivetime':'驾驶时间',
    'vehiclelist.drivedistance':'驾驶里程',

    'map.list_field_plateNo':'车牌号: ',
    'map.list_field_agentID':'设备ID: ',
    'map.not_found':'车辆未找到！',
    'map.back_to_location':'默认视图',
    'map.acc':'急加速',
    'map.braking':'急刹车',
    'map.suddenturn':'急转弯',
    'map.suddenturnL':'左急转弯',
    'map.suddenturnR':'右急转弯',
    'map.roadpotholes':'道路坑洼',
    'map.accident':'事故',
    'map.seriousaccident':'严重事故',

    'devicelist.list_field_agentID':'设备ID',
    'devicelist.list_field_engineNo':'车辆识别码',
    'devicelist.list_field_cmergeate':'使用分类',
    'devicelist.list_field_devtype':'设备类型',
    'devicelist.list_field_sn':'序列号',
    'devicelist.list_field_imei':'IMEI',
    'devicelist.list_field_imsi':'IMSI',
    'devicelist.list_field_iccid':'ICCID',
    'devicelist.list_field_fver':'固件版本',
    'devicelist.list_field_hver':'硬件版本',
    'devicelist.list_field_starttime':'创建时间',
    'devicelist.list_field_status':'设备状态',
    'devicelist.list_field_workmode':'工作模式',
    'devicelist.list_search_placeholder':'根据设备ID搜索',
    'devicelist.list_title':'设备列表',
    'devicelist.list_field_agentID_maxlength':'设备ID长度必须少于15位',
    'devicelist.delete_confirm':'您确定要删除此设备吗？',
    'devicelist.no_device':'无可用设备！',
    'devicelist.list_field_history':'日志',
    'devicelist.list_field_relate':'关联',
    'devicelist.field_plateNo_placeholder':'请输入车牌号',

    'paramsetting.list_title':'参数设置',
    'paramsetting.reboot_dlg_title':'选择重启类型',
    'paramsetting.reboot_dlg_title_soft':'软重启',
    'paramsetting.reboot_dlg_title_cold':'冷重启',
    'paramsetting.reboot_dlg_confirm_title':'警告！设备将重启！',
    'paramsetting.reboot_dlg_confirm':'请确认是否进行此操作!',
    'paramsetting.operate_failed':'操作失败! 其它操作正在进行',

    'paramsetting.list_field_agentID':'设备ID',
    'paramsetting.list_field_devtype':'设备类型',
    'paramsetting.list_field_time':'更新时间',
    'paramsetting.list_field_workingmode':'工作模式',
    'paramsetting.list_field_rptInterval':'GPS上报间隙(s)',
    'paramsetting.list_field_hreatBeatInterval':'心跳上报间隙(s)',
    'paramsetting.list_field_sensorInterval':'传感器采集频率(Hz)',
    'paramsetting.list_field_canInterval':'OBD采集频率(Hz)',
    'paramsetting.list_field_envInterval':'环境上报间隙(s)',
    'paramsetting.list_field_dbgMode':'调试模式',
    'paramsetting.list_field_dbgModule':'调试模块',
    'paramsetting.list_field_msgType':'最后消息',
    'paramsetting.list_field_State':'最后状态',
    'paramsetting.list_field_timeStamp':'最后消息时间',

    'triplist.list_field_startTime':'开始时间',
    'triplist.list_field_endTime':'结束时间',
    'triplist.list_field_mileage':'里程',
    'triplist.list_field_startAddress':'开始地点',
    'triplist.list_field_endAddress':'结束地点',
    'triplist.list_field_driveTime':'时间',
    'triplist.list_field_deviceID':'设备',
    'triplist.delete_confirm':'您确定要删除此次行程吗？',
    'triplist.trip':'行程',
    'triplist.travel':'回放',
    'triplist.networkdata':'网络数据',
    'triplist.obddata':'OBD数据',
    'triplist.maxmum_trip_lines':'最多可显示{0}条数据',
    'triplist.list_geofence_enable':'电子围栏',
    'triplist.list_Event_enable':'事件',
    'triplist.total_time':'总计时间',
    'triplist.total_distance':'总计里程',
    'triplist.showstatistics':'统计信息',
    'triplist.clearselectedtrip':'清除选择',
    'triplist.merge':'合并行程',
    'triplist.merge_confirm':'注意！合并行程后无法恢复，请谨慎操作！',

    'triplistvideo.title':'行程信息',
    'triplistvideo.normal_speed':'正常速度',
    'triplistvideo.one_time_speed':'一倍速度',
    'triplistvideo.two_time_speed':'二倍速度',
    'triplistvideo.three_time_speed':'三倍速度',
    'triplistvideo.four_time_speed':'四倍速度',
    'triplistvideo.five_time_speed':'五倍速度',
    'triplistvideo.restart':'重新开始',
    'triplistvideo.load_map_failed':'加载地图失败，请刷新',
    'triplistvideo.scale':'缩放',
    'triplistvideo.speed':'速度',
    'triplistvideo.playspeed':'回放速度',
    'triplistvideo.maxspeed':'最高：',
    'triplistvideo.avgspeed':'平均：',
    'triplistvideo.pacespeed':'步速：',

    'tripnetworkdata.list_title':'网络数据',
    'tripnetworkdata.time':'时间',
    'tripnetworkdata.longitude':'经度',
    'tripnetworkdata.latitude':'纬度',
    'tripnetworkdata.speed':'速度(Km/h)',
    'tripnetworkdata.networkstate':'状态',
    'tripnetworkdata.rssi':'信号强度(dbm)',
    'tripnetworkdata.signal':'强度',
    'tripnetworkdata.operator':'运营商',

    'tripobddata.list_title':'OBD数据',
    'tripobddata.time':'时间',
    'tripobddata.longitude':'经度',
    'tripobddata.latitude':'纬度',
    'tripobddata.state':'状态',
    'tripobddata.revs':'转速(RPM)',
    'tripobddata.coolanttemp':'冷却液温度(℃)',
    'tripobddata.battvolt':'电池电压(V)',
    'tripobddata.fuellevel':'剩余油量(%)',

    'speedchart.title':'速度(Km/h)',
    'speedchart.field_speed':'速度',
    'speedchart.field_time':'时间',
    'speedchart.field_limit':'限速',
    'networkstatechart.title':'网络状态(0-断网/1-正常)',
    'rssichart.title':'信号强度(dbm)',
    'plmnchart.title':'运营商',
    'menulist.list_field_menucode':'菜单编码',
    'menulist.list_field_menuname':'菜单名称',
    'menulist.list_field_menuclass':'菜单Class属性值',
    'menulist.list_field_dataurl':'菜单data-url属性值',
    'menulist.list_field_sequence':'排序',
    'menulist.list_field_parentmenuCode':'上级菜单编码',
    'menulist.list_search_placeholder':'根据菜单名称搜索',
    'menulist.list_title':'菜单管理',
    'menulist.list_buttons':'按钮权限',
    'rolelist.field_description':'角色描述',
    'rolelist.field_roleValue':'角色名称',
    'rolelist.field_roleKey':'角色编码',
    'rolelist.list_title':'角色权限管理',
    'rolelist.role_authorize':'角色授权',
    'rolelist.role_required':'请选择权限',

    'geofencelist.fields_detail_title':'电子围栏详情',
    'geofencelist.fields_name':'围栏名称',
    'geofencelist.fields_almType':'警告类型',
    'geofencelist.fields_almtype_0':'进围栏告警',
    'geofencelist.fields_almtype_1':'出围栏告警',
    'geofencelist.fields_almtype_2':'出入围栏警告',
    'geofencelist.fields_disType':'通知方式',
    'geofencelist.fields_disType_0':'界面',
    'geofencelist.fields_disType_1':'短信',
    'geofencelist.fields_disType_2':'短信和界面',
    'geofencelist.fields_fenceType':'围栏类型',
    'geofencelist.fields_fenceType_0':'圆形',
    'geofencelist.fields_fenceType_1':'多边形',
    'geofencelist.fields_phoneno':'手机号码',
    'geofencelist.fields_exptime':'过期时间',
    'geofencelist.fields_exptime_show':'过期时间',
    'geofencelist.fields_description':'描述',
    'geofencelist.fields_area':'范围',
    'geofencelist.fields_phoneno.required':'请输入手机号码',
    'geofencelist.fields_exptime.required':'请选择过期时间',
    'geofencelist.fields_fencetype.required':'请在地图上绘制围栏',
    'geofencelist.delete_confirm':'您确定要删除此围栏吗？',
    'geofencelist.relate_plateno':'关联车牌',
    'geofencelist.no_platenos':'无可分配车辆',
    'geofencelist.platenos.required':'请选择车牌号',
    'geofencelist.phone_number_invalid':"您输入的手机号码格式不正确",
    'geofencelist.search':"请输入围栏名称",

    'setfence.mapsearch':"请输入地址",

    'messagelist.delete_confirm':'您确定要删除此消息吗？',
    'messagelist.field.title':'消息类别',
    'messagelist.field.content':'消息内容',
    'messagelist.field.severity':'等级',
    'messagelist.field.refreshTime':'时间',
    'messagelist.list_title':'消息列表',
    'messagelist.list_search_placeholder':'根据类别或内容搜索',
    'messagelist.setasread':'设为已读',
    'messagelist.muiltipledelete':'批量删除',
    'messagelist.id.required_read':'请选择要设为已读的项',
    'messagelist.id.required':'请选择要删除的项',
    'messagelist.search_title':'消息内容',
    'messagelist.search_level':'消息类别',

    'versionlist.page_title':'版本升级向导',
    'versionlist.step_1':'设备选择',
    'versionlist.step_2':'设备版本',
    'versionlist.step_3':'版本信息',
    'versionlist.step_4':'完成',
    'versionlist.next':'下一步',
    'versionlist.prev':'上一步',
    'versionlist.done':'完成',
    'versionlist.back':'返回列表',
    'versionlist.version_page_title':'升级列表',
    'versionlist.version_page_add':'新增版本',
    'versionlist.version_search_placeholder':'输入版本号',
    'versionlist.fields_version_number':'目标版本',
    'versionlist.fields_device_type':'设备类型',
    'versionlist.fields_upgrade_name':'升级名称',
    'versionlist.fields_version_file':'版本文件',
    'versionlist.button_select_file':'选择文件',
    'versionlist.fields_publish_date':'创建时间',
    'versionlist.fields_status':'状态',
    'versionlist.fields_file':'文件',
    'versionlist.fields_file_size':'大小',
    'versionlist.fields_policy_customization':'定制策略',
    'versionlist.fields_action_type':'操作状态',
    'versionlist.fotarule_title':'升级列表',
    'versionlist.fotarule_upgrade_version':'升级版本：',
    'versionlist.fotares_device_id':'设备ID',
    'versionlist.fotares_current_version':'设备版本',
    'versionlist.fotares_target_version':'目标版本',
    'versionlist.fotares_download_time':'创建时间',
    'versionlist.fotares_upgrade_time':'升级时间',
    'versionlist.fotares_status':'状态',
    'versionlist.fotares_error_info':'错误信息',
    'versionlist.fotares_processing':'进度',
    'versionlist.fotares_title':'升级结果',
    'versionlist.device_one_category':'只能选择一个分类下的设备',
    'versionlist.device_required':'请选择分类下的设备',
    'versionlist.version_required':'请选择设备版本',
    'versionlist.fota.devices':'设备',
    'versionlist.fota.version':'版本',
    'versionlist.fota.versioninfo':'版本名称',
    'versionlist.fota.versionnumber':'版本号',
    'versionlist.delete_confirm':'您确定要删除此版本信息吗？',
    'versionlist.cannot_delete':'正在升级，无法删除!',
    'versionlist.canceled':'已经取消升级!',
    'versionlist.cancel_confirm':'您确定要删取消升级吗？',
    'versionlist.step_4_verinfo':'升级信息:',
    'versionlist.step_4_fotaname':'升级名称:',
    'versionlist.step_4_verno':'版本号:',
    'versionlist.step_4_devinfo':'设备信息:',
    'versionlist.step_4_basever':'设备版本:',
    'plocy.info':'您在使用我们的服务时，我们公司可能会收集和使用您的相关信息。我们希望向您说明在使用我们的服务时，我们如何收集、使用、储存和分享这些信息。希望您仔细阅读隐私权政策，在需要时按照指引作出您认为适当的选择。如果您使用我司提供的任一服务的，您的使用行为将被视为对本声明全部内容的认可，即意味着您同意我们按照隐私权政策收集、使用、储存和分享您的相关信息。' +
        '<br/><br/>' +
        '1.适用范围' +
        '<br/>' +
        'a）在您注册或激活可以登录我们的账户时，您在网页提供的个人或企业的注册信息；' +
        '<br/>' +
        'b）在您使用网页或移动客户端时，我们自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用网页或移动客户端软件，或访问移动网页使用我们的服务时，我们可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。' +
        '<br/>' +
        'c）我们收集到的您在网页或移动客户端发布和使用的有关数据信息等，以及您在使用过程中通过硬件设备，应用程序向我们平台上传的各种数据， 您输入的车辆，保险，位置，第三方服务等。' +
        '<br/>' +
        'd）我们通过合法途径从商业伙伴处取得的用户个人数据。' +
        '<br/><br/>' +
        '您了解并同意，以下信息不适用本隐私权政策：' +
        '<br/>' +
        'a）您在使用网页或移动客户端提供的搜索和使用服务时输入的关键字信' +
        '<br/>' +
        'b）违反法律规定或违反网页或移动客户端规则行为。' +
        '<br/><br/>' +
        '2.信息使用' +
        '<br/>' +
        'a）我们不会向第三方(我们的关联公司除外)提供、分享您的个人信息，除非事先得到您的许可，或该第三方和我们（含我们的关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。' +
        '<br/>' +
        'b)我们亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何用户如从事上述活动，一经发现，我们有权立即终止与该用户的服务协议。' +
        '<br/>' +
        'c)为服务用户的目的，我们或其关联公司可能通过使用您的个人和企业信息，向您提供您可能感兴趣的信息，包括但不限于向您发出产品和服务信息，或通过系统向您展示个性化的第三方推广信息，或者与我们的合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）' +
        '<br/>' +
        'd)我们可以使用您的个人信息以预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联公司协议、政策或规则的行为，以保护您、其他用户，或我们或其关联公司合法权益。' +
        '<br/><br/>' +
        '3.信息披露在如下情况下，我们将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：' +
        '<br/>' +
        'a) 经您事先同意，向第三方披露；b) 根据法律、法规、证券交易规则或根据政府机关、证券交易所的披露要求，向第三方或者相关方或行政、司法机构的披露；' +
        '<br/>' +
        'c) 如您出现违反中国有关法律、法规或者我们的服务协议或相关规则的情况，需要向第三方披露；' +
        '<br/>' +
        'd) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；' +
        '<br/>' +
        'e) 其它我们根据法律、法规或者网站政策认为合适的披露。' +
        '<br/><br/>' +
        '4.信息存储和交换' +
        '<br/>' +
        '我们收集的有关您的信息和资料将保存在我们及（或）其关联公司的服务器或隶属于我们及（或）其关联公司所采用拥有全部管理权和使用权的云服务设施上，这些信息和资料可能传送至您所在国家、地区或我们收集信息和资料所在地的境外并在境外被访问、存储和展示。' +
        '<br/>' +
        'a)在您未拒绝接受cookies的情况下，我们会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的服务或功能。我们使用cookies可为您提供更加周到的个性化服务，包括推广服务。' +
        '<br/>' +
        'b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的服务或功能。' +
        '<br/>' +
        'c)通过我们所设cookies所取得的有关信息，将适用本政策；' +
        '<br/><br/>' +
        '6.信息安全' +
        '<br/>' +
        'a)您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”；' +
        '<br/>' +
        'b)在使用我们的服务进行信息发布时，您可能要向对方披露自己的个人和企业信息，如车牌号码，驾驶证信息，发动机号码等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是你的账户及密码发生泄露，请您立即联络我们service@huabaotech.com以便采取相应措施。' +
        '<br/><br/>' +
        '7.未成年人的特别注意事项' +
        '<br/>' +
        '如果您不是具备完全民事权利能力和完全民事行为能力的自然人，您无权使用我们的服务，因此我们希望您不要向我们提供任何个人信息。' +
        '<br/><br/>' +
        '8.隐私权政策的更新' +
        '<br/>' +
        'a)我们的隐私权政策可能修订。' +
        '<br/>' +
        'b)未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。我们会在专门页面上发布对隐私权政策所做的任何修订。' +
        '<br/>' +
        'c)对于重大修订，我们还会提供更为显著的通知（包括对于某些服务，我们会通过网站公示的方式进行通知，说明隐私权政策的具体变更内容）。' +
        '<br/>' +
        'd)本政策所指的重大变更包括但不限于：' +
        '<br/>' +
        '&nbsp&nbsp1.我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；' +
        '<br/>' +
        '&nbsp&nbsp2.我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；' +
        '<br/>' +
        '&nbsp&nbsp3.用户信息共享、转让或公开披露的主要对象发生变化；' +
        '<br/>' +
        '&nbsp&nbsp4.您参与用户信息处理方面的权利及其行使方式发生重大变化；' +
        '<br/>' +
        '&nbsp&nbsp5.我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；' +
        '<br/>' +
        '&nbsp&nbsp6.用户信息安全影响评估报告表明存在高风险时。'
    
}

export default zh_CN;