import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Department {
    // 部门下拉数据
    @observable availableDepartments = []

    // 部门列表
    @observable dataSource = []

    // 部门实例
    @observable model = {
        id: 0,
        departmentKey: '',
        departmentKeyStatus: null,//验证状态
        departmentValue: '',
        departmentValueStatus: null,//验证状态
        parentDepartmentValue: '',
        description: ''
    }

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0
    }


    @action setData(list, model, selectData, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (model !== undefined && model !== null) {
            var _model = Object.assign({}, this.model, model);
            this.model = _model;
        }
        if (selectData !== undefined && selectData !== null) {
            this.availableDepartments = selectData;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action initModel = () => {
        this.model = {
            id: 0,
            departmentKey: '',
            departmentKeyStatus: null,
            departmentValue: '',
            departmentValueStatus: null,
            parentDepartmentValue: '',
            description: ''
        }
    }
}
export default new Department();