import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Message {
    // 消息列表
    @observable dataSource = []

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0,
        searchField:'title',
        name: ''
    }

    @observable UnReadMessage={
        Count:0,
        MsgLevel:0,
        List:[]
    };

    @action setData(list, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action selectedAllItems=(selected)=>{
        var data = this.dataSource.slice();
        data.forEach(item=>{
            item.checked=selected;
        })
        this.dataSource = data;
    }

    @action setChangeCheckbox=(id)=>{
        var data = this.dataSource.slice();
        var currentItem = data.filter(x=>x.id===id);
        if(currentItem.length>0){
            currentItem[0].checked=!currentItem[0].checked
        }
        this.dataSource = data;
    }

    @action setUnReadMessage=(data)=>{
        let _data = Object.assign({},this.UnReadMessage,data)
        this.UnReadMessage=_data;
    }
}
export default new Message()