import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Steps {
    @observable deviceList = []
    @observable versionList = []
    @observable checkedVersions = []
    @observable stepThree = {
        versionNumber: '',
        fotaName: '',
        filePath: null
    }
    @action setDeviceList = (data) => {
        this.deviceList = data;
    }

    @action setSubDeviceList = (data, parentId) => {
        let _data = this.deviceList.slice();
        let _index = _data.findIndex(x => x.key === parseInt(parentId, 10));
        if (_index !== -1) {
            _data[_index].subs = data;
        }
        this.deviceList = _data;
    }
    @action setCheckedDevice = (parentKey, key) => {
        let device = this.deviceList.slice();
        device.forEach(item => {
            if (parentKey === 0) {
                if (item.key === key) {
                    item.selected = !item.selected;
                    if (item.subs) {
                        item.subs.forEach(sub => {
                            sub.selected = item.selected;
                        })
                    }
                }
            } else {
                if (item.key === parentKey) {
                    var subItems = item.subs.filter(x => x.key === key);
                    if (subItems !== undefined && subItems.length > 0) {
                        subItems[0].selected = !subItems[0].selected;
                    }
                }
            }
        })
        this.deviceList = device;
    }
    @action setVersionList = (data) => {
        this.versionList = data;
    }
    @action setCheckedVersions = (checkedItems) => {
        this.checkedVersions = checkedItems;
    }
    @action setStepThree = (data) => {
        let _model = Object.assign({}, this.stepThree, data);
        this.stepThree = _model;
    }

    @action initAll = () => {
        this.deviceList = []
        this.versionList = []
        this.checkedVersions = []
        this.stepThree = {
            versionNumber: '',
            fotaName: '',
            filePath: null
        }
    }

}

export default new Steps();