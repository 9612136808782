import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Menu {
    // 用户列表
    @observable dataSource = []

    @observable menuButtons = []

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 10,
        pageCount: 0,
        name: ''
    }
    @action setData(list, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action setMenuButtons = (data) => {
        this.menuButtons = data;
    }

    @action setButtons = (data) => {
        let _buttons = this.menuButtons.slice();
        _buttons.forEach(item => {
            var selecteds = item.btnlist.filter(x=>x.id===data.id);
            if(selecteds.length>0){
                selecteds[0].checked = data.checked;
            }
        })
        this.menuButtons = _buttons;
    }
}
export default new Menu();