import config from '../stores/config';
import department from '../stores/department';
import user from '../stores/user';
import device from '../stores/device';
import vehicle from '../stores/vehicle';
import trip from '../stores/trip';
import dashboard from '../stores/dashboard';
import menu from '../stores/menu';
import role from '../stores/role';
import geofence from '../stores/geofence';
import message from '../stores/message';
import steps from '../stores/steps'
import version from '../stores/version'
import fotares from '../stores/fotares'
import networkdata from '../stores/networkdata'
import obddata from '../stores/obddata'
import search from '../stores/search'
import paramsetting from '../stores/paramsetting'

export default {
    config,
    department,
    user,
    device,
    vehicle,
    trip,
    dashboard,
    menu,
    role,
    geofence,
    message,
    steps,
    version,
    fotares,
    networkdata,
    obddata,
    search,
    paramsetting
};