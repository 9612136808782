import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Vehicle {
    // 分类下拉数据
    @observable availableCategories = []

    // 用户列表
    @observable dataSource = []

    // 用户实例
    @observable model = {
        id: 0,
        idNo:'',
        agentID: "",
        brand: '',
        cate: 0,
        cateName: '',
        engineNo: null,
        jsonFilters: null,
        listFilter: [],
        model: '',
        name: '',
        phoneNo: '',
        plateNo: '',
        plateNoStatus: '',
        purchaseDate: '',
        purchaseDateStatus: null,
        latitude:0,
        longitude:0,
        geoFenceDescription:'',
        driveTime:'',
        driveDistance:''
    }

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0,
        name: ''
    }


    @action setData(list, model, categoris, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (model !== undefined && model !== null) {
            var _model = Object.assign({}, this.model, model);
            this.model = _model;
        }
        if (categoris !== undefined && categoris !== null) {
            let cates=[];
            categoris.forEach(item=>{
                cates.push({id:item.cateId,name:item.name});
            })
            this.availableCategories = cates;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action initModel = () => {
        this.model = {
            id: 0,
            idNo:'',
            agentID: "",
            brand: '',
            cate: 0,
            cateName: '',
            engineNo: '',
            jsonFilters: '',
            listFilter: [],
            model: '',
            name: '',
            phoneNo: '',
            plateNo: '',
            plateNoStatus: '',
            purchaseDate: '',
            purchaseDateStatus: null,
            latitude:0,
            longitude:0,
            geoFenceDescription:'',
            driveTime:'',
            driveDistance:''
        }
    }
}
export default new Vehicle();