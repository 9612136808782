import * as tools from '../../../tools/tool'
function getBiggest(currentMax, count) {
    if ((currentMax*10) % count === 0 && currentMax!==0) {
        return currentMax;
    }

    currentMax++;

    return getBiggest(currentMax, count);
}
function getdate(now) {
    let y = now.getFullYear(),
        m = now.getMonth() + 1,
        d = now.getDate();
    return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
}
function ChartDrawer(targetElement, options) {
    if (options === undefined || options === null || typeof (options) === 'string') {
        options = {};
    }

    if (targetElement === undefined) {
        throw "target container is required"
    }

    let opt = {
        width: 500,
        height: 500,
        container: null,
        data: [],
        xAliasCount: 5,
        yAliasCount: 10,
        xAliasLabel: ["0", "2", "4", "6", "8"],
        yAliasLabel: ["0", "21.01", "31.01", "41.01", "51.01", "61.01", "71.01", "81.01", "91.01", "101.01"],
        paddingTop: 25,
        paddingLeft: 30,
        aliasColor: '#000',
        scaleWidth: 5,
        //以下为动态获取
        marginTop: 0,
        marginLeft: 0,
        xScaleHeight: null,
        yScaleHeight: null,
        RealWidth: null,
        RealHeight: null,
        RealData: [],
        showIndex: null,
        point: null,
        maxspeed: 0,
        avgspeed: 0,
        pacespeed: 0,
    }

    this.options = Object.assign({}, opt, options);
    this.container = targetElement;

    var canvas = document.createElement('canvas');

    canvas.width = this.options.width;
    canvas.height = this.options.height;

    targetElement.append(canvas);
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');

    //计算MarginTop 和 ScaleHeight

    this.drawXYAlias = () => {
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.options.aliasColor;
        this.ctx.fillStyle = this.options.aliasColor;
        this.ctx.font = "12px Arial";
        // 移动到（10，10）
        this.ctx.moveTo(this.options.paddingLeft, this.options.paddingTop);
        let endPointY = this.options.RealHeight + this.options.paddingTop;
        // 画线到x轴的底部坐标
        this.ctx.lineTo(this.options.paddingLeft, endPointY);
        // 画线到y轴的结束坐标
        let endPointX = this.options.RealWidth + this.options.paddingLeft;
        this.ctx.lineTo(endPointX, endPointY);

        //开始画X轴刻度
        for (var i = 0; i < this.options.xAliasCount - 1; i++) {
            if (i !== 0) {
                this.ctx.moveTo(this.options.paddingLeft, this.options.paddingTop + i * this.options.xScaleHeight);
                this.ctx.lineTo(this.options.paddingLeft + this.options.scaleWidth, this.options.paddingTop + i * this.options.xScaleHeight);
            }
            this.ctx.fillText(this.options.xAliasLabel[this.options.xAliasLabel.length - 1 - i], 10, this.options.paddingTop + i * this.options.xScaleHeight + 5)
        }

        //开始画Y轴刻度
        for (var i = 1; i < this.options.yAliasCount - 1; i++) {
            this.ctx.moveTo(this.options.paddingLeft + i * this.options.yScaleHeight, this.options.height - this.options.paddingTop);
            this.ctx.lineTo(this.options.paddingLeft + i * this.options.yScaleHeight, this.options.height - this.options.paddingTop + this.options.scaleWidth);
            let _text = this.options.yAliasLabel[i];
            let _textWidth = this.ctx.measureText(_text).width;
            this.ctx.fillText(_text, this.options.paddingLeft + i * this.options.yScaleHeight - _textWidth / 2, this.options.height - this.options.paddingTop + this.options.scaleWidth + 10)
        }
        this.ctx.stroke();
    }

    this.calculateAliasHeight = (isX) => {
        let that = this;
        let _count = isX ? (this.options.xAliasCount - 1) : (this.options.yAliasCount - 1);
        if (isX) {
            if ((this.options.height - this.options.marginTop - 2 * this.options.paddingTop) % _count == 0) {
                this.options.paddingTop = this.options.marginTop / 2 + this.options.paddingTop;
                let _margin = this.options.marginTop % 2;
                this.options.xScaleHeight = (this.options.height - 2 * this.options.paddingTop - _margin) / _count;
                this.options.RealHeight = this.options.height - 2 * this.options.paddingTop - _margin;
            } else {
                this.options.marginTop++;
                that.calculateAliasHeight(isX);
            }
        } else {
            if ((this.options.width - this.options.marginLeft - 2 * this.options.paddingLeft) % _count == 0) {
                this.options.paddingLeft = this.options.marginLeft / 2 + this.options.paddingLeft;
                let _margin = this.options.marginLeft % 2;
                this.options.yScaleHeight = (this.options.width - 2 * this.options.paddingLeft - _margin) / _count;
                this.options.RealWidth = this.options.width - 2 * this.options.paddingLeft - _margin;
            } else {
                this.options.marginLeft++;
                that.calculateAliasHeight(isX);
            }
        }
    }

    this.setData = () => {
        let _realWidth = this.options.RealWidth;
        let firstDate = new Date(this.options.data[0].dtTimeStamp).getTime();
        let lastDate = new Date(this.options.data[this.options.data.length - 1].dtTimeStamp).getTime();
        let timeGap = lastDate - firstDate;
        let _maxY = 0;
        let totalspeed = 0;

        let pxWidthList = this.options.data.map(item => {
            let _stamp = new Date(item.dtTimeStamp).getTime();
            let _x = parseInt((_stamp - firstDate) * _realWidth / timeGap, 10);
            let _y = item.iSpeed;

            if (_y > _maxY) {
                _maxY = _y;
            }

            if(_y > this.options.maxspeed) {
                this.options.maxspeed = _y;
            }

            totalspeed += _y;

            return { x: _x, y: _y }
        });

        this.options.avgspeed = totalspeed / pxWidthList.length;

        if (this.options.showIndex !== null && this.options.showIndex<this.options.data.length) {
            this.options.point = pxWidthList[this.options.showIndex];
            this.options.point.time = this.options.data[this.options.showIndex].dtTimeStamp
            this.options.point.speed = this.options.data[this.options.showIndex].iSpeed
        } else {
            this.options.point = null;
        }

        this.options.RealData = pxWidthList;

        if (_maxY == 0) {
            _maxY = 10;
        }
        
        _maxY = getBiggest(_maxY, this.options.xAliasCount - 1);
        this.options.maxY = _maxY;
        let _gap = _maxY / (this.options.xAliasCount - 1);
        let alisaXLabels = [];
        for (var i = 0; i < this.options.xAliasCount; i++) {
            alisaXLabels.push(i * _gap);
        }
        this.options.xAliasLabel = alisaXLabels;

        let _gapX = parseInt(timeGap / (this.options.yAliasCount - 1), 10);
        let alisaYLabels = [];
        for (var i = 0; i < this.options.yAliasCount; i++) {
            let _time = i * _gapX + firstDate;
            let _newTime = new Date(_time);
            alisaYLabels.push(getdate(_newTime));
        }
        this.options.yAliasLabel = alisaYLabels;
    }

    this.drawChart = () => {
        let _realHeight = this.options.RealHeight;
        let _realWidth = this.options.RealWidth;
        if (this.options.RealData.length > 0) {
            this.ctx.beginPath();
            this.ctx.strokeStyle = "#2F7EF8";
            this.ctx.lineWidth = 2;
            this.ctx.moveTo(this.options.RealData[0].x + this.options.paddingLeft, _realHeight + this.options.paddingTop - this.options.RealData[0].y);
            for (var i = 1; i < this.options.RealData.length; i++) {
                let _y = this.options.RealData[i].y * _realHeight / this.options.maxY;
                this.ctx.lineTo(this.options.RealData[i].x + this.options.paddingLeft, _realHeight + this.options.paddingTop - _y);
            }
            this.ctx.stroke();
        }

        //draw current point
        if (this.options.point !== null) {
            this.ctx.beginPath();
            this.ctx.strokeStyle = "red";
            this.ctx.lineWidth = 2;
            let _realX = this.options.point.x + this.options.paddingLeft;
            this.ctx.moveTo(_realX, 0);
            this.ctx.lineTo(_realX, _realHeight + this.options.paddingTop - 1.5);
            this.ctx.stroke();
            this.ctx.beginPath();
            this.ctx.strokeStyle = "#1890ff";
            this.ctx.fillStyle = "#ffffff";
            this.ctx.lineWidth = 2;
            let _y = this.options.point.y * _realHeight / this.options.maxY;
            this.ctx.arc(_realX, _realHeight + this.options.paddingTop - _y, 3, 0, Math.PI * 2, true);
            this.ctx.fill();
            this.ctx.stroke();

            let _time = `${tools.GetLocalizationResource('speedchart.field_time')}:${this.options.point.time}`;
            let _textTimeWidth = this.ctx.measureText(_time).width;
            let _speed = `${tools.GetLocalizationResource('speedchart.field_speed')}:${this.options.point.speed}km/h`;
            let _textSpeedWidth = this.ctx.measureText(_speed).width;
            let _width = (_textTimeWidth > _textSpeedWidth ? _textTimeWidth : _textSpeedWidth)+10;
            this.ctx.beginPath();
            this.ctx.font = "12px Arial";
            this.ctx.fillStyle = "#000000";
            if(_realX+_width>=_realWidth){
                _realX = _realX-_width;
            }

            this.ctx.fillText(_speed, _realX + 5, 10);
            this.ctx.fillText(_time, _realX + 5, 25);
        }
    }
    this.clearCanvas = () => {
        this.ctx.fillStyle = '#ffffff';   //填充颜色
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
    this.initDraw = (showIndex) => {
        if (showIndex !== undefined && showIndex !== null) {
            this.options.showIndex = showIndex;
        } else {
            this.options.showIndex = null;
        }
        this.options = Object.assign({}, this.options, {
            marginTop: 0,
            marginLeft: 0,
            xScaleHeight: null,
            yScaleHeight: null,
            RealWidth: null,
            RealHeight: null,
            RealData: [],
        })
        this.clearCanvas();
        this.calculateAliasHeight(true);
        this.calculateAliasHeight(false);
        this.setData();
        this.drawXYAlias();
        this.drawChart();
    }

    this.initDraw();
}

export default ChartDrawer;