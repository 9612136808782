import axios from 'axios'
import history from '../components/history';
import Cookies from 'universal-cookie';
import zh_CN from '../locale/zh-CN';
import en_US from '../locale/en-US';
import lessStyles from '../index_mobile.module.less'
import { notification } from 'antd'
const cookies = new Cookies();

var CancelToken = axios.CancelToken;
var cancel;
var siteStore;


//set global info
global.Processing = {
    // 防止401错误时死循环
    HasRedirected: null,
    // 跳转链接
    RedirectUrl: null
}

const AUTH_TOKEN = 'AUTHTOKEN';
export function setCookie(c_name, value, expiredays) {
    cookies.set(c_name, value, { path: '/' });
}

//取回cookie
export function getCookie(c_name) {
    let _value = cookies.get(c_name);
    if (_value === undefined || _value === null)
        return "";
    return _value;
}

export function GetSiteToken() {
    let _token = "";

    _token = GetFromLocal(AUTH_TOKEN);
    GetFromLocal("zoom");

    return _token === undefined || _token === null || _token === '' ? `JSESSIONID=${get_cookieStr()};rememberMe=deleteMe` : _token;
}

export function SetSiteToken(token) {
    SetToLocal(AUTH_TOKEN, token);

    GetFromLocal("zoom");

    axios.defaults.headers.common['language'] = GetLanguage();
    axios.defaults.headers.common['maptype'] = GetFromLocal('MapType');

    axios.defaults.headers.common['Authorization'] = token;
}

export function SetToLocal(key, value) {
    if(window.localStorage){
        var storage = window.localStorage;

        try {
            storage.setItem(key, value);
        } catch (ex) {
        }
    }

    try {
        setCookie(key, value);
    } catch (ex) {
    }

    if (key === "Language") {
        axios.defaults.headers.common['language'] = value;
    }
}

export function GetFromLocal(key) {
    var ret = null;

    if(window.localStorage){
        var storage = window.localStorage;

        try {
            ret = storage.getItem(key);
        } catch (ex) {
        }
    }

    if(ret === null){
        try {
            ret = getCookie(key);
        } catch (ex) {

        }
    }

    if (key === "Language" &&  ret === null) {
        ret = "en-US";

        setCookie(key, ret);
        localStorage.setItem(key, ret);
    }

    if (key === "MapType" &&  ret === null) {
        ret = "gaode";

        setCookie(key, ret);
        localStorage.setItem(key, ret);
    }

    if (key === "zoom" &&  ret === null) {
        ret = 14;

        setCookie(key, ret);
        localStorage.setItem(key, ret);
    }

    if (key === "autologin" &&  ret === null) {
        ret = false;

        setCookie(key, ret);
        localStorage.setItem(key, ret);
    }

    return ret;
}

export function SetToCookie(key, value) {
    try {
        setCookie(key, value);
    } catch (ex) {

    }
}

export function GetFromCookie(key) {
    var ret = null;

    try {
        ret = getCookie(key);
    } catch (ex) {

    }

    return ret;
}


export function GetFromStorage(key) {
    var ret = null;

    if(window.localStorage){
        var storage = window.localStorage;

        try {
            ret = storage.getItem(key);
        } catch (ex) {
        }
    }

    return ret;
}

export function LogOut() {
    SetSiteToken('');
    SetToLocal('LoginUser', '');
    SetToLocal('autologin', false);
    SetToLocal('remembered', false);
    history.push('/');
}

export function ClearMessage(){
    if (global.MessageInterval !== undefined && global.MessageInterval !== null) {
        clearInterval(global.MessageInterval);
        global.MessageInterval = null;
    }
    notification.close('notify');
}

function get_cookieStr() {
    var s = []
    var hexDigits = '0123456789ABCDEF'
    for (var i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    const uuid = s.join('')
    return uuid
}
export function GenerateUUID() {
    return get_cookieStr();
}
// axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*';
axios.defaults.headers.common["language"] = GetLanguage();

let defaultMapType = GetFromLocal("MapType");
if (defaultMapType === '')
    defaultMapType = 'gaode';

axios.defaults.headers.common['maptype'] = defaultMapType;

let offset = -(new Date().getTimezoneOffset() / 60);
axios.defaults.headers.common['timezone'] = offset;
axios.defaults.withCredentials = true;
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (config.url.indexOf('getInformation') == -1) {
        siteStore.config.changeLoading(true);
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    siteStore.config.changeLoading(false);
    return Promise.reject(error);
});
axios.defaults.maxRedirects = 2;
// 添加响应拦截器
axios.interceptors.response.use((response) => {
    // 对响应数据做点什么
    siteStore.config.changeLoading(false);

    if (typeof (response.data) === 'string') {
        if (response.data.indexOf('doctype html') != -1) {
            location.href = '/'
        }
    }
    return response;
}, (error) => {
    siteStore.config.changeLoading(false);
    if (cancel)
        cancel()
    // 对响应错误做点什么
    if (error.response !== undefined) {
        switch (error.response.status) {
            case 401:
                global.Processing.RedirectUrl = GetCurrentLocation();
                SetSiteToken('');
                DoRedirect('/')
                break;
            case 403:
                siteStore.config.changeMessage(error.response.data.message, 'error');
                global.Processing.RedirectUrl = GetCurrentLocation();
                history.replace('/');
                break;
            default:
                siteStore.config.changeMessage(error.response.data.message, 'error');
                break;
        }
    }
    return Promise.reject(error);
});
function GetCurrentLocation() {
    let _path = history.location.pathname
    if (history.location.search !== undefined && history.location.search !== null && history.location.search !== '') {
        _path += history.location.search;
    }
    return _path;
}
function DoRedirect(path) {
    if (global.Processing.HasRedirected === null) {
        setTimeout(() => {
            global.Processing.HasRedirected = true;
            history.replace(path);
        }, 500)
    } else {
        global.Processing.HasRedirected = null;
    }
}

export function GetRootUrl() {
    return window.GPSDBBaseUrl === undefined ? 'http://140.207.75.118:9880/rmc/' : window.GPSDBBaseUrl;//'http://lbs.dioload.com/';//;'http://localhost:54405/';//
}

let _SiteToken = GetSiteToken();
if (_SiteToken === undefined || _SiteToken === 'undefined' || _SiteToken === null)
    _SiteToken = '';
axios.defaults.baseURL = GetRootUrl();
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common["Authorization"] = _SiteToken;

export function Get(url) {
    return axios.get(url, {
        maxRedirects: 5,
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    })
        .catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                return Promise.reject(thrown);
            } else {
                return Promise.reject(thrown);
            }
        });
}

export function Post(url, data) {
    return axios.post(url, data, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    }).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
            return Promise.reject(thrown);
        } else {
            return Promise.reject(thrown);
        }
    });
}

export function GetBlob(url, data) {
    return axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: data,
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancel = c;
        })
    }).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
            return Promise.reject(thrown);
        } else {
            return Promise.reject(thrown);
        }
    });
}

export function DownloadBlob(res) {
    if (!res.data) {
        return
    }
    var blob = new Blob([res.data], { type: 'application/msexcel;charset=UTF-8' })//xlsx 使用 application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    var url = window.URL.createObjectURL(blob);
    var aLink = document.createElement("a");
    aLink.style.display = "none";
    aLink.href = url;
    aLink.setAttribute("download", 'file.xls');
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink); //下载完成移除元素
    window.URL.revokeObjectURL(url); //释放掉blob对象
}


export function InitStore(store) {
    siteStore = store;
}


export function Common_GetSearchParams(search) {
    let params = {};
    if (search !== undefined && search !== '') {
        search = search.substring(1);
        let ps = search.split('&');
        for (var i = 0; i < ps.length; i++) {
            let m = ps[i].split('=');
            if (m.length > 2) {
                //value中含有=的情况
                let z = ps[i].substring(0, ps[i].indexOf('='));
                let v = ps[i].substring(ps[i].indexOf('=') + 1);
                m = [z, v];
            }
            let hasKey = false;
            for (var key in params) {
                if (key === m[0].trim()) {
                    hasKey = true;
                }
            }

            if (hasKey) {
                var old = params[m[0].trim()];
                if (typeof old === 'object') {
                    params[m[0].trim()].push(m[1].trim());
                } else {
                    let newVs = [];
                    newVs.push(params[m[0]].trim());
                    newVs.push(m[1].trim());
                    params[m[0].trim()] = newVs;
                }
            } else {
                params[m[0].trim()] = m[1].trim();
            }
        }
    }
    return params;
}
export function isWeiXin() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
    } else {
        return false;
    }
}

export function CategoryBreadcrumnb(data, overrideDefualt) {
    let defaultCategory = [{ key: 0, value: 0, title: 'No Parent', children: [] }];
    if (overrideDefualt !== undefined) {
        defaultCategory = overrideDefualt;
    }
    if (data === null || data.length === 0) {
        return [{ key: 0, value: 0, title: 'No Parent', children: [] }];
    }
    var parents = data.filter(p => p.parentId === 0);
    let newData = parents.map((item, index) => {
        let newItem = { key: item.id, value: item.id, title: item.name, children: [] };
        return iterationCategory(newItem, data);
    });
    return defaultCategory.concat(newData);
}

function iterationCategory(parent, allData) {
    var subs = allData.filter(p => p.parentId === parent.key);
    if (subs !== null && subs.length > 0) {
        let childrens = subs.map((item, index) => {
            let newItem = { key: item.id, value: item.id, title: item.name, children: [] };
            return iterationCategory(newItem, allData);
        });
        parent.children = childrens;
    }
    return parent;
}
export function GetLanguage() {
    var lan = GetFromLocal('Language');
    if (lan === '') {
        lan = 'en-US';
    }
    return lan;
}
export function GetLocalizationResource(key) {
    switch (GetLanguage()) {
        default:
        case 'en-US':
            return en_US[key];
        case 'zh-CN':
            return zh_CN[key];
    }
}

export function ToKiloMeter(meter) {
    return (parseFloat(meter) / 1000.00).toFixed(2).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g,'$&,') + " Km";
}


export function ExportExcelCompnent() {
    var form = "<form name='csvexportform' action='/rmc/sys/authority/operateAuthority?oper=excel' method='post'>";
    form = form + "<input type='hidden' name='csvBuffer' value='" + encodeURIComponent(rows) + "'>";
    form = form + "</form><script>document.csvexportform.submit();</script>";
    OpenWindow = window.open('', '');
    OpenWindow.document.write(form);
    OpenWindow.document.close();
}

export function jumpToLogin(res) {
    if (typeof (res.data) === 'string') {

        if (res.data.indexOf('doctype html') != -1) {
            location.href = '/'
            return false;
        }
    }
    return true;
}

///根据时间获取颜色
export function getFillColor(time, type) {
    if (type === undefined) {
        type = "bgColor"
    }
    if (time === undefined || time === null || time === '')
        return type === 'bgColor' ? "" : 'bg-red';
    var date = new Date(time).getTime();
    var dateNow = new Date().getTime();
    var stamp = dateNow - date;
    if (stamp >= 0) {
        //过期
        return type === 'bgColor' ? "red" : 'bg-red';
    } else {
        // 未过期
        let days = Math.floor(Math.abs(stamp) / (24 * 3600 * 1000));
        if (days < 7) {
            return type === 'bgColor' ? '#FFA500' : 'bg-yellow';
        } else {
            //     return type === 'bgColor' ? '#dedede' : 'bg-grey';
        }
    }
}

// coords为{latitude:1111,longitude:222222}
export function GetGeoLocation(callBack) {
    try {
        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(callBack, (err) => {
                console.log(err)
                switch (err.code) {
                    case err.PERMISSION_DENIED:
                        console.log("获取地理位置：", "用户拒绝了访问地理位置");
                        break;
                    case err.POSITION_UNAVAILABLE:
                        console.log("获取地理位置：", "无法找到地理位置信息");
                        break;
                    case err.TIMEOUT:
                        console.log("获取地理位置：", "获取用户地理位置超时");
                        break;
                }
                callBack({ coords: null });
            }, { timeout: 2000 });
        }
        else {
            callBack({ coords: null });
        }
    } catch (ex) {
        callBack({ coords: null });
    }
}

export function SetLocationInfo(obj) {
    let position = GetFromLocal("GeoLocation");
    if (position !== undefined && position !== "undefined" && position !== "") {
        if (typeof (position) === 'string') {
            position = JSON.parse(position);
        }
    } else {
        position = { coords: null, zoom: 14 }
    }
    let _newInfo = Object.assign({}, position, obj);
    SetToLocal("GeoLocation", JSON.stringify(_newInfo));
}
export const styles = lessStyles

export function HasSpecialCharacter(str) {
    var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]");
    if (pattern.test(str)) {
        return true;
    }
    return false;
}