import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Search {
    @observable filters = {
        groupOp: "",
        rules:[]
    }

    @observable rule = {
        type: "string",
        op: "like",
        field: null,
        data: null
    }

    @action addRule(groupOp,type,op,field,data) {

        if (this.filters !== undefined && this.filters !== null) {
            this.filters.groupOp = groupOp;
            this.filters.rules.clear();

            if (this.rule !== undefined && this.rule !== null) {
                this.rule.type = type;
                this.rule.op = op;
                this.rule.field = field;
                this.rule.data = data;
            }

            this.filters.rules.push(this.rule);
        }
    }

    @action init = () => {
        this.filters = {
            groupOp: "",
            rules:[]
        }

        this.rule = {
            type: "string",
            op: "like",
            field: "",
            data: ""
        }
    }
}
export default new Search();