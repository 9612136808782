import React, {Component} from 'react'
import {observer, inject} from 'mobx-react'
import ChartDrawer from '../../../tools/tripvideochart/chartdraw/speed'
import * as tools from '../../../tools/tool'
import {Tooltip} from "antd";

@inject('store')
@observer
class SpeedChart extends Component {
    state = {
        avg:0,
        max:0,
        pace:""
    }
    setCvChart = (ref) => {
        this.cvChat = ref;
    }
    componentDidMount = () => {
        let that = this;
        window.showSpeedChart = () => {
            const {TripInfo, TripGuiJiData} = that.props.store.trip;
            let TripDataItem = TripGuiJiData.filter(x => x.tripId === TripInfo.TripId)[0];
            var guijiData = TripDataItem.data.filter(x => x.EventType === 19);

            var chat = new ChartDrawer(
                that.cvChat, {
                    width: this.cvChat.clientWidth,
                    height: 150,
                    data: guijiData
                });

            window.SpeedChatModel = chat;

            let pace = TripInfo.TripTime / 60.0 /(TripInfo.mileage / 1000.0) * 60.0;

            let paceSpeed = parseInt(pace / 60).toString().padStart(2, "0") + ":" + parseInt(pace % 60).toString().padStart(2, "0");

            that.setState({
                avg:window.SpeedChatModel.options.avgspeed.toFixed(1),
                max:window.SpeedChatModel.options.maxspeed,
                pace:paceSpeed
            })
        }
    }

    componentWillUnmount = () => {
        window.showSpeedChart = null;
    }

    render() {
        return (
            <div>
                <div style={{background: '#fff', color: '#333', fontSize: '16px', padding: '5px 5px',display:'inline'}}>
                    {tools.GetLocalizationResource('speedchart.title')}
                </div>
                <div className="tripvideo-speedchart">
                    <b>
                        {tools.GetLocalizationResource('triplistvideo.avgspeed')}{this.state.avg} km/h | {tools.GetLocalizationResource('triplistvideo.maxspeed')}{this.state.max} km/h | {tools.GetLocalizationResource('triplistvideo.pacespeed')}{this.state.pace} min/km
                    </b>
                </div>
                <div className="canvas-container" ref={this.setCvChart}>
                </div>
            </div>
        );
    }
};

export default SpeedChart;