import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class User {
    // 部门下拉数据
    @observable availableDepartments = []

    // 部门下拉数据
    @observable availableRoles = []

    // 用户列表
    @observable dataSource = []

    // 用户实例
    @observable model = {
        id: 0,
        userName: '',
        userNameStatus: null,
        sex: 1,
        birthday: '',
        departmentKey: null,
        departmentKeyStatus: null,
        email: '',
        emailStatus: null,
        emailValidate: '',
        lastLoginTime: null,
        company: '',
        companyStatus: null,
        companyValidate: '',
        password: '',
        passwordStatus: null,
        passwordValidate: '',
        resetPassword: '',
        resetPasswordStatus: null,
        resetPasswordValidate: '',
        phone: '',
        rememberMe: false,
        statusCn:true,
        status: true,
        roles: [],
        roleKey: null,
        roleKeyStatus: null,
        agreeProtocal:false,
        agreeProtocalStatus:null,
        agreeProtocalValidate:''
    }

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0,
        name: ''
    }


    @action setData(list, model, departments, roles, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (model !== undefined && model !== null) {
            var _model = Object.assign({}, this.model, model);
            this.model = _model;
        }
        if (departments !== undefined && departments !== null) {
            this.availableDepartments = departments;
        }
        if (roles !== undefined && roles !== null) {
            this.availableRoles = roles;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action initModel = () => {
        this.model = {
            id: 0,
            userName: '',
            userNameStatus: null,
            sex: 1,
            birthday: '',
            departmentKey: null,
            departmentKeyStatus: null,
            email: '',
            emailStatus: null,
            emailValidate: '',
            lastLoginTime: null,
            company: '',
            companyStatus: null,
            companyValidate: '',
            password: '',
            passwordStatus: null,
            passwordValidate: '',
            resetPassword: '',
            resetPasswordStatus: null,
            resetPasswordValidate: '',
            phone: '',
            rememberMe: false,
            statusCn:true,
            status: true,
            roles: [],
            roleKey: null,
            roleKeyStatus: null,
            agreeProtocal:false,
            agreeProtocalStatus:null,
            agreeProtocalValidate:''
        }
    }
}
export default new User();