import { observable, action, configure, computed } from 'mobx'
import { GetSiteToken, GetLocalizationResource, GetFromLocal } from "../tools/tool"
import history from '../components/history';
configure({ enforceActions: 'always' });

// messageType: error, warning, success
class Config {
    @observable messageInfo = {
        message: "",
        messageType: 'error'
    }
    @observable pageSize = 20;
    @observable defaultOrderItemCount = 8;
    @observable isLoading = [];
    @observable menus = []
    @observable menuSelection = {
        parentKey: null,
        openKey: null,
        selectedKey: null
    }
    @observable navigationBar = [];
    @observable profile = {
        userName: '',
        avatar: '',
        isMobile: null
    }
    @observable permissions = [];
    @action changeMessage = (msg, errorType) => {
        this.messageInfo = {
            message: msg,
            messageType: errorType
        }
        let that = this;
        if (msg != '') {
            global.MessageInterval = setTimeout(() => {
                global.MessageInterval = null;
                if (that.messageInfo.message !== '') {
                    that.changeMessage('', 'error');
                }
            }, 10000);
        }
    }
    @action changeLoading = (isLoad) => {
        if (isLoad) {
            this.isLoading.push(1);
        } else {
            this.isLoading.splice(0, 1);
        }
    }


    @action setMenu = (data) => {
        this.menus = data;
        this.setCurrentBreadcrums()
    }

    @action SetPermission = (permissions) => {
        this.permissions = permissions;
    }

    @computed get isLogin() {
        let token = GetSiteToken();
        if (token === "") {
            return false;
        } else {
            return true;
        }
    }

    @computed get MapType() {
        let mapType = GetFromLocal('MapType');
        if (mapType === '') {
            return 'gaode';
        }
        return mapType;
    }

    @action setNavigationBar() {
        let extendItems = "";
        let _path = history.location.pathname;
        if (_path.indexOf('/trip/') !== -1) {
            extendItems = "trip";
            _path = "/admin/page/vehiclelist";
        } else if (_path.indexOf('/tripvideo') !== -1) {
            extendItems = "tripvideo";
            _path = "/admin/page/vehiclelist";
        } else if (_path.indexOf('/tripnetworkdata') !== -1) {
            extendItems = "tripnetworkdata";
            _path = "/admin/page/vehiclelist";
        } else if (_path.indexOf('/tripobddata') !== -1) {
            extendItems = "tripobddata";
            _path = "/admin/page/vehiclelist";
        }

        let items = this.menus.filter(x => x.url === _path);

        let _list = [];
        if (items.length > 0) {
            let _last = items[0];
            if (_last.parentId !== 0) {
                let _subs = this.menus.filter(x => x.id === _last.parentId);
                if (_subs.length > 0) {
                    let _sub = _subs[0];
                    if (_sub.parentId != 0) {
                        let parents = this.menus.filter(x => x.id === _sub.parentId);
                        if (parents.length > 0) {
                            _list.push({ url: parents[0].url, name: parents[0].name });
                        }
                    }
                    _list.push({ url: _sub.url, name: _sub.name });
                }
            }
            _list.push({ url: _last.url, name: _last.name });
        }
        switch (extendItems) {
            case "trip":
                _list.push({ url: "", name: 'commonlist.trip' });
                break;
            case "tripvideo":
                _list.push({ url: "", name: 'triplist.travel' });
                break;
            case "tripnetworkdata":
                _list.push({ url: "", name: 'triplist.networkdata' });
                break;
            case "tripobddata":
                _list.push({ url: "", name: 'triplist.obddata' });
                break;
            default:
                break;
        }
        this.navigationBar = _list;
    }

    setBreadcrums = () => {
        let _url = history.location.pathname;

        let items = this.menus.filter(x => _url === x.url && x.parentId !== 0);

        if (items.length === 0) {
            items = this.menus.filter(x => _url.indexOf(x.url) !== -1 && x.parentId !== 0);
        }

        if (items.length > 0) {
            var bread = items[0];
            let topId = 0;
            var parents = this.menus.filter(x => x.id === bread.parentId);
            if (parents.length > 0) {
                topId = parents[0].parentId;
            }
            return { id: bread.id, parentId: topId }
        }
        var _parents = this.menus.filter(x => x.parentId === 0);
        let parentId = 0;
        if (_parents.length > 0) {
            parentId = _parents[0].id;
        }
        return { id: null, parentId: parentId };
    }

    @action setCurrentBreadcrums() {
        var bread = this.setBreadcrums();
        if (bread !== null) {
            let pathName = history.location.pathname;
            if (pathName == "/admin") {
                this.setMenuSelection({
                    parentKey: ["-1"],
                    selectedKey: null
                })
            } else {
                this.setMenuSelection({
                    parentKey: bread === null ? null : [bread.parentId.toString()],
                    selectedKey: bread === null ? null : bread.id === null ? null : [bread.id.toString()]
                })
            }
        }
        this.setNavigationBar()
    }

    @action setMenuSelection(info) {
        let newSelection = Object.assign({}, this.menuSelection, info);
        this.menuSelection = newSelection;
    }

    @action setMenu = (menu) => {
        let _cMenu = [];
        if (menu === undefined || menu === null) {
            return;
        }
        let subKeys = [];
        menu.forEach(item => {
            _cMenu.push({
                id: item.id,
                parentId: 0,
                menuCode: item.menuCode,
                name: item.menuName,
                url: item.dataUrl,
                icon: item.menuClass
            })
            _cMenu.push({
                id: item.id,
                parentId: -1,
                menuCode: item.menuCode,
                name: item.menuName,
                url: item.dataUrl,
                icon: item.menuClass
            })
            if (item.subAuthorityList !== undefined) {
                item.subAuthorityList.forEach(subItem => {
                    _cMenu.push({
                        id: subItem.id,
                        parentId: item.id,
                        menuCode: subItem.menuCode,
                        name: subItem.menuName,
                        url: subItem.menuCode === 'systemmonitor' ? `/admin` : `/admin/${subItem.dataUrl}`,
                        icon: subItem.menuClass
                    })
                    subKeys.push(subItem.id.toString());
                    if (subItem.subAuthorityList !== undefined) {
                        subItem.subAuthorityList.forEach(thirdItem => {
                            _cMenu.push({
                                id: thirdItem.id,
                                parentId: subItem.id,
                                menuCode: thirdItem.menuCode,
                                name: thirdItem.menuName,
                                url: thirdItem.menuCode === 'systemmonitor' ? `/admin` : `/admin/${thirdItem.dataUrl}`,
                                icon: thirdItem.menuClass
                            })
                        });
                    }
                })
            }
        });
        this.menus = _cMenu;
        this.setMenuSelection({ openKey: subKeys });
        this.setCurrentBreadcrums();
    }

    @action setProfile = (data) => {
        let _data = Object.assign({}, this.profile, data);
        this.profile = _data
    }
}

export default new Config;