import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Device {
    // 分类下拉数据
    @observable availableCategories = []

    // 用户列表
    @observable dataSource = []

    // 用户实例
    @observable model = {
        id: 0,
        agentID: "",
        devName: '',
        devType: 0,
        fuo: '',
        fver: '',
        hver: '',
        iccid: '',
        imei: '',
        sn: '',
        startTime: '',
        status: '',
        workMode: ''
    }

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0,
        name: ''
    }
    //设备类型和设备（用于绑定树形菜单）
    @observable deviceTypeAndList = []
    @observable selectedVehicleDevice=[]

    //车牌号  用于绑定属性菜单
    @observable plateNoList=[]
    @observable selectedPlateNos=[]

    @action setData(list, model, categoris, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (model !== undefined && model !== null) {
            var _model = Object.assign({}, this.model, model);
            this.model = _model;
        }
        if (categoris !== undefined && categoris !== null) {
            this.availableCategories = categoris;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action initModel = () => {
        this.model = {
            id: 0,
            agentID: "",
            agentIDValidateStatus:null,
            devName: '',
            devType: '',
            devTypeValidateStatus:null,
            fuo: '',
            fver: '',
            hver: '',
            iccid: '',
            imei: '',
            sn: '',
            startTime: '',
            status: '',
            workMode: ''
        }
    }

    @action SetDeviceTypeList(data) {
        this.deviceTypeAndList = data;
    }

    @action SetDeviceTypeListSub(id, data) {
        var _result = this.deviceTypeAndList.slice();
        var items = _result.filter(x => x.id === id);
        if (items.length > 0) {
            items[0].children = data;
        }
        this.deviceTypeAndList=_result;
    }

    @action SaveSelectedVehicleDevice=(data)=>{
        this.selectedVehicleDevice=data;
    }

    @action SetPlateNoList(data,currentPlateNo){
        if(currentPlateNo!==undefined && currentPlateNo!==null && currentPlateNo!==""){
            let items = data.filter(x=>x.title===currentPlateNo);
            if(items.length>0){
                this.selectedPlateNos=[items[0].id.toString()];
            }
        }else{
            this.selectedPlateNos=[];
        }
        this.plateNoList = data;
    }

    @action SaveSelectedPlateNos=(data)=>{
        this.selectedPlateNos=data;
    }
}
export default new Device();