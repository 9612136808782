import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class ParamSetting {
    // 分类下拉数据
    @observable availableCategories = []

    // 用户列表
    @observable dataSource = []

    // 用户实例
    @observable model = {
        agentID: "",
        devName: '',
        workingMode: 1,
        hreatBeatInterval: 1800,
        rptInterval: 1,
        sensorInterval: 0,
        envInterval: 0,
        dbgModule: 0,
        dbgMode: 0,
        canInterval: 0
    }

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 40,
        pageCount: 0,
        name: ''
    }

    @action setData(list, model, categoris, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (model !== undefined && model !== null) {
            var _model = Object.assign({}, this.model, model);
            this.model = _model;
        }
        if (categoris !== undefined && categoris !== null) {
            this.availableCategories = categoris;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action initModel = () => {
        this.model = {
            agentID: "",
            devName: '',
            workingMode: 1,
            hreatBeatInterval: 1800,
            rptInterval: 1,
            sensorInterval: 0,
            envInterval: 0,
            dbgModule: 0,
            dbgMode: 0,
            canInterval: 0
        }
    }
}
export default new ParamSetting();