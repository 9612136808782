import React from 'react'
const loading = require('../images/loading.gif')

const MyLoadingComponent = ({ isLoading, error }) => {
    // Handle the loading state
    if (isLoading) {
        return <div className="site-loading"><div className="gpsdb-mask"></div>
        <div className="gpsdb-loading">
                    <img src={loading} style={{ marginTop: '6px' }} />
                </div>
        </div>;
    }
    // Handle the error state
    else if (error) {
        return <div>Sorry, there was a problem loading the page.</div>;
    }
    else {
        return null;
    }
};

export default MyLoadingComponent