const en_US={
    'login.log_in':'Login',
    'login.register':'Register',
    'login.language_zh':'中文',
    'login.language_en':'English',
    'login.log_in_now':'Login',
    'login.log_in_process':'Please wait...',
    'login.forgot_pass':'Forgot Password',
    'login.user_name':'Username',
    'login.placeholder_username':'Please enter your username',
    'login.placeholder_password':'Please enter your password',
    'login.placeholder_email':'Please enter your email',
    'login.back_login':'Back to login',
    'login.get_back_password':'Password Reset',
    'login.send_email':'Send',
    'login.sending_email':'Sending...',
    'login.validation_email_required':'Email is required',
    'login.validation_email_notvalid':'Email is not existed, please check',
    'login.find_email_success':'Email is sent, please wait administrator to reset.',
    'login.find_password_faild':'Password reset failed',
    'login.login_other':'Change Account',
    'layout.header_welcome':'Welcome',
    'layout.header_personal_info':'Setting',
    'layout.header_logout':'Log Out',
    'layout.header_construction':'Construction! ',

    'layout.back_to_location':'',

    'dashboard.summary_onload':'Driving',
    'dashboard.summary_stopped':'Stopped',
    'dashboard.summary_total_drived':'Drive Distance',
    'dashboard.summary_total_hours':'Drive Time',
    'dashboard.summary_exception_device':'Disconnected',
    'dashboard.summary_urgent_event':'Emergency',
    'dashboard.report_total_devices':'Statistics',
    'dashboard.report_car_total_drived':'Drive Distance',
    'dashboard.report_car_total_hours':'Drive Time',
    'dashboard.report_mileage':'Drive Distance',
    'dashboard.report_drving_time':'Drive Time',
    'dashboard.stop_driving':'Stopped',
    'dashboard.is_driving':'Driving',
    'userlist.list_title':'User List',
    'userlist.list_search_placeholder':'Please enter search information.',
    'userlist.list_field_username':'Username',
    'userlist.list_field_sex':'Sex',
    'userlist.list_field_email':'Email',
    'userlist.list_field_phone':'Phone',
    'userlist.list_field_company':'Company',
    'userlist.list_field_birthday':'Birthday',
    'userlist.list_field_department':'Department',
    'userlist.list_field_role':'Role',
    'userlist.list_field_inactive':'Actived',
    'userlist.list_field_lastlogin':'Login Time',
    'userlist.delete_confirm':'Are you sure to delete the user? ',
    'commonlist.location':'Location',
    'commonlist.action':'Operation',
    'commonlist.add':'Add',
    'commonlist.download':'Export',
    'commonlist.edit':'Edit',
    'commonlist.delete':'Delete',
    'commonlist.print':'Print',
    'commonlist.viewdetail':'View Detail',
    'commonlist.detail':'Details',
    'commonlist.search':'Search',
    'commonlist.yes':'Yes',
    'commonlist.no':'No',
    'commonlist.empty':'No one',
    'commonlist.select':'Please select',
    'commonlist.ok':'Save',
    'commonlist.cancel':'Cancel',
    'commonlist.failed':'Operation failure',
    'commonlist.success':'Operation successfully',
    'commonlist.required':'This field is required',
    'commonlist.notify':'Notification',
    'commonlist.pagenation':'from {0} to {1} total records {2}',
    'commonlist.man':'Male',
    'commonlist.woman':'Female',
    'commonlist.resetpass':'Reset Password',
    'commonlist.trip':'Trip',
    'commonlist.change':'Change',
    'commonlist.fire':'Unbind',
    'commonlist.bind':'Bind',
    'commonlist.show':'Show',
    'commonlist.combine':'Bind',
    'commonlist.dragmap':'Click to drag map',
    'commonlist.drawcircle':'Click to set circle area',
    'commonlist.drawpolygon':'Click to set polygon area',
    'commonlist.zhongbiaohistory':'History',
    'commonlist.history':'History',
    'commonlist.newmessage':'New Messages',
    'commonlist.rememberpassword':'Remember Me',
    'commonlist.autologin':'Auto Login',
    'commonlist.bgcolor':'Backcolor: ',
    'commonlist.actived':'Actived ',
    'commonlist.expired':'Expired ',
    'commonlist.neareexpired':'Soon to expire ',
    'commonlist.view':'View',
    'commonlist.reset':'Clear',
    'commonlist.reboot':'Reboot',
    'commonlist.paramsetting':'Param Setting',

    'common.norecords':'No Records',
    'common.home':'Home',
    'common.message':'Message',
    'common.back':'Go Back',
    'common.header':'Avatar',
    'common.googlemap':'Google Map',
    'common.baidumap':'BaiDu Map',
    'common.gaodemap':'GaoDe Map',
    'common.showmore':'Show More',
    'departmentlist.list_title':'Department',
    'departmentlist.list_field_code':'Code',
    'departmentlist.list_field_name':'Name',
    'departmentlist.list_field_parent_level':'Parent Name',
    'departmentlist.list_field_desc':'Parent Describe',
    'departmentlist.delete_confirm':'Are you sure to delete the department? ',
    'profile.field.username':'Username',
    'profile.field.sex':'Gender',
    'profile.field.email':'Email',
    'profile.field.phone':'Phone',
    'profile.field.brithday':'Birthday',
    'profile.field.company':'Company',
    'profile.field.avatar':'Avatar',
    'profile.field.personal.info':'Setting',
    'profile.field.password':'Password',
    'register.field.username.required':'Username is required',
    'register.field.username.special.character':"Special character is not valid",
    'register.field.email.required':'Email is required',
    'register.field.password.required':'Password is required',
    'register.field.company.required':'Company is required',
    'register.field.resetPassword.required':'Password confirm is required',
    'register.field.resetPassword.notmatch':"Sorry, the password doesn't match",
    'register.field.agreeProtocal.required':'You should accept the license first.',
    'register.field.username':'Username',
    'register.field.email':'Email',
    'register.field.birthday':'Birthday',
    'register.field.phone':'Phone',
    'register.field.company':'Company',
    'register.field.password':'Password',
    'register.field.resetPassword':'Password confirm',
    'register.button.register':'Submit',
    'register.button.register.processing':'Submit...',
    'register.title':'Register',
    'register.userprotocal.accept':'I have read and accepted',
    'register.userprotocal':'privacy policy',
    'register.field.email.existed':'Email is existed',
    'register.field.password.existed':'Password is not correct',
    'register.register.success':'Register success. Please wait for approval.',

    'vehiclelist.longitude':'Lng',
    'vehiclelist.latitude':'Lat',
    'vehiclelist.list_detail_title':'Vehicle Detailed Inforamtion',
    'vehiclelist.list_field_plateNo':'Plate No',
    'vehiclelist.list_field_idNo':'ID No',
    'vehiclelist.list_field_engineNo':'Engine',
    'vehiclelist.list_field_cate':'Category',
    'vehiclelist.list_field_brand':'Brand',
    'vehiclelist.list_field_model':'Vehicle Type',
    'vehiclelist.list_field_purchaseDate':'Purchase Date(YYYY-MM-DD)',
    'vehiclelist.list_field_purchaseDate_placeholder':'Purchase Date',
    'vehiclelist.list_field_name':'Name',
    'vehiclelist.list_field_phoneNo':'Phone No',
    'vehiclelist.list_field_agentID':'Device ID',
    'vehiclelist.list_title':'Vehicle List',
    'vehiclelist.list_search_placeholder':'Search by device ID',
    'vehiclelist.unlinkdevice':'Are you sure to unbind the device？',
    'vehiclelist.device.required':'device is required',
    'vehiclelist.delete_confirm':'Are you sure to delete this car?',
    'vehiclelist.plateNo.required':'Plate no is required',
    'vehiclelist.purchaseDate.required':'Purchase date is required',
    'vehiclelist.column.name.plateNo':'Plate No',
    'vehiclelist.column.name.agentID':'Device ID',
    'vehiclelist.list_field_geofence':'Related Geofence:',
    'vehiclelist.nolocation':'No location info.',
    'vehiclelist.drivetime':'Drive Time',
    'vehiclelist.drivedistance':'Drive Distance',

    'map.list_field_plateNo':'Plate No: ',
    'map.list_field_agentID':'Device ID: ',
    'map.not_found':'Vechile not found!',
    'map.back_to_location':'Back to Default View',
    'map.acc':'Harsh Acceleration',
    'map.braking':'Harsh Brake',
    'map.suddenturn':'Sudden Turn',
    'map.suddenturnL':'Sudden Turn Left',
    'map.suddenturnR':'Sudden Turn Right',
    'map.roadpotholes':'Road Potholes',
    'map.accident':'Accident',
    'map.seriousaccident':'Serious Accident',

    'devicelist.list_field_agentID':'Device ID',
    'devicelist.list_field_engineNo':'ID No',
    'devicelist.list_field_cate':'Category',
    'devicelist.list_field_devtype':'Device Type',
    'devicelist.list_field_sn':'SN',
    'devicelist.list_field_imei':'IMEI',
    'devicelist.list_field_imsi':'IMSI',
    'devicelist.list_field_iccid':'ICCID',
    'devicelist.list_field_fver':'Firmware Version',
    'devicelist.list_field_hver':'Hardware Version',
    'devicelist.list_field_starttime':'Registration Date',
    'devicelist.list_field_status':'Status',
    'devicelist.list_field_workmode':'Work Mode',
    'devicelist.list_search_placeholder':'Search by device ID',
    'devicelist.list_title':'Device List',
    'devicelist.list_field_agentID_maxlength':'Device id length must smaller than 15',
    'devicelist.delete_confirm':'Are you sure to delete the device',
    'devicelist.no_device':'Not available device!',
    'devicelist.list_field_history':'History',
    'devicelist.list_field_relate':'Relate',
    'devicelist.field_plateNo_placeholder':'Please enter your plate No',

    'paramsetting.list_title':'Param Setting',
    'paramsetting.reboot_dlg_title':'Please choose reboot type',
    'paramsetting.reboot_dlg_title_soft':'Soft Reboot',
    'paramsetting.reboot_dlg_title_cold':'Cold Reboot',
    'paramsetting.reboot_dlg_confirm_title':'Warning! The device will reboot!',
    'paramsetting.reboot_dlg_confirm':'Please confirm to do it!',
    'paramsetting.operate_failed':'Operate Failed. Another operate has done.',

    'paramsetting.paramsetting_dlg_title':'Parameter Setting',

    'paramsetting.list_field_agentID':'Device ID',
    'paramsetting.list_field_devtype':'Device Type',
    'paramsetting.list_field_time':'Update Time',
    'paramsetting.list_field_workingmode':'Working Mode',
    'paramsetting.list_field_rptInterval':'GPS INVL(s)',
    'paramsetting.list_field_hreatBeatInterval':'Hreatbeat INVL(s)',
    'paramsetting.list_field_sensorInterval':'Sensor Freq(Hz)',
    'paramsetting.list_field_canInterval':'OBD Freq(Hz)',
    'paramsetting.list_field_envInterval':'Env INVL(s)',
    'paramsetting.list_field_dbgMode':'Debug Mode',
    'paramsetting.list_field_dbgModule':'Debug Module',
    'paramsetting.list_field_msgType':'Last Msgtype',
    'paramsetting.list_field_State':'Last State',
    'paramsetting.list_field_timeStamp':'Last Msg Time',

    'triplist.list_field_startTime':'Start Time',
    'triplist.list_field_endTime':'Stop Time',
    'triplist.list_field_mileage':'Drive Distance',
    'triplist.list_field_startAddress':'Start Position',
    'triplist.list_field_endAddress':'Destination',
    'triplist.list_field_driveTime':'Drive Time',
    'triplist.list_field_deviceID':'Device',
    'triplist.delete_confirm':'Are you sure to delete the trip?',
    'triplist.trip':'Show',
    'triplist.travel':'Replay',
    'triplist.networkdata':'Network Data',
    'triplist.obddata':'OBD Data',
    'triplist.maxmum_trip_lines':'Up to {0} data can be displayed',
    'triplist.list_geofence_enable':'Geo Fence',
    'triplist.list_Event_enable':'Event',
    'triplist.total_time':'Total Time',
    'triplist.total_distance':'Total Distance',
    'triplist.showstatistics':'Statistics',
    'triplist.clearselectedtrip':'Clear Selected Trips',
    'triplist.merge':'Merge',
    'triplist.merge_confirm':'Warning! This operation cannot be cancelled.',

    'triplistvideo.title':'Trip Info',
    'triplistvideo.normal_speed':'Normal',
    'triplistvideo.one_time_speed':'1x Speed',
    'triplistvideo.two_time_speed':'2x Speed',
    'triplistvideo.three_time_speed':'3x Speed',
    'triplistvideo.four_time_speed':'4x Speed',
    'triplistvideo.five_time_speed':'5x Speed',
    'triplistvideo.restart':'Restart',
    'triplistvideo.load_map_failed':'Load map failed, please refresh.',
    'triplistvideo.scale':'Scale',
    'triplistvideo.speed':'Speed',
    'triplistvideo.playspeed':'Speed',
    'triplistvideo.maxspeed':'Max：',
    'triplistvideo.avgspeed':'Avg：',
    'triplistvideo.pacespeed':'Pace：',

    'tripnetworkdata.list_title':'Network Data',
    'tripnetworkdata.time':'Time',
    'tripnetworkdata.longitude':'Longitude',
    'tripnetworkdata.latitude':'Latitude',
    'tripnetworkdata.speed':'Speed（Km/h）',
    'tripnetworkdata.networkstate':'Network Status',
    'tripnetworkdata.rssi':'Signal (dbm)',
    'tripnetworkdata.signal':'Level',
    'tripnetworkdata.operator':'Operator',

    'tripobddata.list_title':'OBD Data',
    'tripobddata.time':'Time',
    'tripobddata.longitude':'Longitude',
    'tripobddata.latitude':'Latitude',
    'tripobddata.state':'State',
    'tripobddata.revs':'Revs (RPM)',
    'tripobddata.coolanttemp':'Coolant Temperature (℃)',
    'tripobddata.battvolt':'Batt Volt (V)',
    'tripobddata.fuellevel':'Fuel Level (%)',

    'speedchart.title':'Speed（Km/h）',
    'speedchart.field_speed':'Speed',
    'speedchart.field_time':'Time',
    'speedchart.field_limit':'Limit',
    'networkstatechart.title':'Network Status（0-offline/1-online）',
    'rssichart.title':'Signal (dbm)',
    'plmnchart.title':'Operator',

    'menulist.list_field_menucode':'Menu Code',
    'menulist.list_field_menuname':'Name',
    'menulist.list_field_menuclass':'Menu Class',
    'menulist.list_field_dataurl':'Data Url',
    'menulist.list_field_sequence':'Sequence',
    'menulist.list_field_parentmenuCode':'Parent Menu Code',
    'menulist.list_search_placeholder':'Search by menu name',
    'menulist.list_title':'Menu List',
    'menulist.list_buttons':'Button permissions',
    'rolelist.field_description':'Role Description',
    'rolelist.field_roleValue':'Role Name',
    'rolelist.field_roleKey':'Role Code',
    'rolelist.list_title':'Role Permission List',
    'rolelist.role_authorize':'Authorize',
    'rolelist.role_required':'Please select a permission',

    'geofencelist.fields_detail_title':'Geo Fence Detailed Information',
    'geofencelist.fields_name':'Geofence Name',
    'geofencelist.fields_almType':'Alarm Type',
    'geofencelist.fields_almtype_0':'In notify',
    'geofencelist.fields_almtype_1':'Out notify',
    'geofencelist.fields_almtype_2':'In-Out notify',
    'geofencelist.fields_disType':'Notice Type',
    'geofencelist.fields_disType_0':'Message',
    'geofencelist.fields_disType_1':'SMS',
    'geofencelist.fields_disType_2':'Message and SMS',
    'geofencelist.fields_fenceType':'Geofence Type',
    'geofencelist.fields_fenceType_0':'Circle',
    'geofencelist.fields_fenceType_1':'Polygon',
    'geofencelist.fields_phoneno':'Phone No',
    'geofencelist.fields_exptime':'Expiration Date(YYYY-MM-DD)',
    'geofencelist.fields_exptime_show':'Expiration Date',
    'geofencelist.fields_description':'Description',
    'geofencelist.fields_area':'Area',
    'geofencelist.fields_phoneno.required':'Please enter your mobile phone number',
    'geofencelist.fields_exptime.required':'Please select expiration time',
    'geofencelist.fields_fencetype.required':'Please draw a fence on the map',
    'geofencelist.delete_confirm':'Are you sure you want to delete this geo fence?',
    'geofencelist.relate_plateno':'Bind Plate No',
    'geofencelist.no_platenos':'No data',
    'geofencelist.platenos.required':'Please select the license plate number',
    'geofencelist.phone_number_invalid':"The format of the mobile number you entered is incorrect",
    'geofencelist.search':"Please enter geo fence name.",

    'setfence.mapsearch':"Please input address.",

    'messagelist.delete_confirm':'Are you sure you want to delete this message?',
    'messagelist.field.title':'Type',
    'messagelist.field.content':'Content',
    'messagelist.field.severity':'Severity',
    'messagelist.field.refreshTime':'Time ',
    'messagelist.list_title':'Message List',
    'messagelist.list_search_placeholder':'Search by level or title',
    'messagelist.setasread':'Set Readed',
    'messagelist.muiltipledelete':'Batch Delete',
    'messagelist.id.required_read':'Please select items to set as read',
    'messagelist.id.required':'Please select items to delete',
    'messagelist.search_title':'Title',
    'messagelist.search_level':'Level',

    'versionlist.page_title':'FOTA',
    'versionlist.step_1':'Select Devices',
    'versionlist.step_2':'Device Version',
    'versionlist.step_3':'Target Version',
    'versionlist.step_4':'Finish',
    'versionlist.next':'Next',
    'versionlist.prev':'Previous',
    'versionlist.done':'Finish',
    'versionlist.back':'Back to List',
    'versionlist.version_page_title':'FOTA List',
    'versionlist.version_page_add':'Add',
    'versionlist.version_search_placeholder':'Enter Version No.',
    'versionlist.fields_version_number':'Taget Version',
    'versionlist.fields_device_type':'Device Type',
    'versionlist.fields_upgrade_name':'FOTA Name',
    'versionlist.fields_version_file':'Version File',
    'versionlist.button_select_file':'Select File',
    'versionlist.fields_publish_date':'Create Time',
    'versionlist.fields_status':'Status',
    'versionlist.fields_file':'File',
    'versionlist.fields_file_size':'Size',
    'versionlist.fields_policy_customization':'Policy Customization',
    'versionlist.fields_action_type':'Operation Type',
    'versionlist.fotarule_title':'Fota List',
    'versionlist.fotarule_upgrade_version':'Target Version：',
    'versionlist.fotares_device_id':'Device ID',
    'versionlist.fotares_current_version':'Device Version',
    'versionlist.fotares_target_version':'Target Version',
    'versionlist.fotares_download_time':'Create Time',
    'versionlist.fotares_upgrade_time':'Upgrade Time',
    'versionlist.fotares_status':'Status',
    'versionlist.fotares_error_info':'Error Info',
    'versionlist.fotares_processing':'Process',
    'versionlist.fotares_title':'FOTA Result',
    'versionlist.device_one_category':'Please select only one type of device.',
    'versionlist.device_required':'Please select device.',
    'versionlist.version_required':'Please select FOTA file',
    'versionlist.fota.devices':'Device ID',
    'versionlist.fota.version':'Version',
    'versionlist.fota.versioninfo':'FOTA Name',
    'versionlist.fota.versionnumber':'Version No.',
    'versionlist.delete_confirm':'Are you sure to delete FOTA?',
    'versionlist.cannot_delete':'In updating status.Cannot delete!',
    'versionlist.canceled':'Already canceled!',
    'versionlist.cancel_confirm':'Are you sure to cancel FOTA?',
    'versionlist.step_4_verinfo':'FOTA Information:',
    'versionlist.step_4_fotaname':'FOTA Name:',
    'versionlist.step_4_verno':'Version No.:',
    'versionlist.step_4_devinfo':'Device Information:',
    'versionlist.step_4_basever':'Device Version:',
    'plocy.info':'The use of our services may involve the collection and use of your Information. We would like to show you how we collect, use, store and share the information by using our services. Please read the Privacy Policy carefully and make appropriate choices according to the guidelines when necessary. By using any kind of our services, your use will be deemed as recognition of the whole content of this statement, which means that you agree that we may collect, use, store and share your relevant information in accordance with the privacy policy.' +
        '<br/><br/>' +
        '1.Scope of Application' +
        '<br/>' +
        'a)When you register or activate the account, the registration information of individual or enterprise provided by you on the website。' +
        '<br/>' +
        'b)When you use the web page or mobile client, we automatically receive and record the information on your browser and computer, including but not limited to your IP address, browser type, used language, access date and time, software and hardware feature information, web page records and other data required by you; if you download or use the web page or mobile client software, or visit the mobile web page by using our services, we may get the information related to your location and mobile devices, including but not limited to device model, device ID, operating system, resolution, telecom operator, etc.' +
        '<br/>' +
        'c)We collect the relevant data information which you publish and use on the web page or mobile client, as well as the various data you upload to our platform through hardware devices and applications during the use process, the vehicle, insurance, location, third-party services you input, etc.' +
        '<br/>' +
        'd)Personal data of users obtained from business partners through legal channels.' +
        '<br/><br/>' +
        'You understand and agree that this privacy policy does not apply to the following information:' +
        '<br/>' +
        'a)Keyword you enter when using search and service provided by web page or mobile client.' +
        '<br/>' +
        'b)Violation of laws or rules of webpage or mobile client.' +
        '<br/><br/>' +
        '2.Information usage' +
        '<br/>' +
        'a)We will not provide or share your personal information to a third party (other than our affiliates), unless we obtain your permission in advance, or the third party and us (including our affiliates) provide services for you individually or jointly, and after the end of the service, they will be banned from accessing all the materials that they have been able to access previously.' +
        '<br/>' +
        'b)We also do not allow any third party to collect, edit, sell or distribute your personal information free of charge by any means. If any user engages in the above activities, we have the right to terminate the service agreement with the user immediately upon discovery.' +
        '<br/>' +
        'c)For the purpose of serving users, we or our affiliates may provide you with information that you may be interested in by using your personal and corporate information, including but not limited to sending you product and service information, or displaying personalized third-party promotion information to you through the system, or sharing information with our partners so that they can send you information about their products and services (the latter requires your prior consent).' +
        '<br/>' +
        'd)We can use your personal information to prevent, discover, fraud-detection, endanger security, illegally or violate agreements, policies or rules with us or our affiliates, so as to protect the legitimate rights and interests of you, other users, us or our affiliates.' +
        '<br/><br/>' +
        '3.Information disclosure' +
        '<br/>' +
        'Under the following circumstances, we will disclose your personal information in whole or in part according to your personal wishes or legal provisions:' +
        '<br/>' +
        'a)Disclose to a third party with your prior consent;' +
        '<br/>' +
        'b)According to laws, regulations, securities trading rules or disclosure requirements of government agencies and stock exchanges, disclose to third parties or related parties or administrative and judicial institutions.' +
        '<br/>' +
        'c)If you violate the relevant laws and regulations of China or our service agreement or relevant rules, you need to disclose them to a third party.' +
        '<br/>' +
        'd)In order to provide the products and services you require, you have to share your personal information with a third party.' +
        '<br/>' +
        'e)Other disclosures that we consider appropriate according to laws, regulations or website policies.' +
        '<br/><br/>' +
        '4.Information storage and exchange' +
        '<br/>' +
        'The information and data which we collect will be stored on our servers and ( or )our affiliates or the cloud service facilities owned by us and (or) our affiliates, which may be transmitted to your country, region or the place where we collect the information and data, they will be accessed , stored and showed in oversea.' +
        '<br/>' +
        'a)If you do not refuse to accept cookies, we will set or access cookies on your computer so that you can log in or use services or functions that depend on cookies. We use cookies to provide you with more thoughtful personalized services, including promotional services.' +
        '<br/>' +
        'b)You have the right to choose to accept or reject cookies. You can refuse to accept cookies by modifying your browser settings. However, if you choose to refuse to accept cookies, you may not be able to log in or use services or features that depend on cookies.' +
        '<br/>' +
        'c)This policy will apply to information obtained through our cookies;' +
        '<br/><br/>' +
        '5.Information safety' +
        '<br/>' +
        'a)Your account has security protection function. Please keep your account and password information properly. We will ensure that your information won’t be lost, abused or altered through security measures such as backup to other servers and encryption of user passwords. In spite of the aforementioned security measures, please note that there are no "perfect security measures" on the information network.' +
        '<br/>' +
        'b)\tWhen using our services for releasing information, you may need to disclose your personal and enterprise information to the other party, such as license plate number, driver\'s license information, engine number, etc. Please protect your personal information and provide it to others only when necessary. If you find that your personal information is leaked, especially your account and password, please contact us at jay.zhuang@remowirelss.com immediately to take corresponding measures.' +
        '<br/><br/>' +
        '6.Special notes for minors' +
        '<br/>' +
        'If you are not a natural person with full capacity for civil rights and civil conduct, you are not entitled to use our services, so we hope you will not provide us with any personal information.' +
        '<br/><br/>' +
        '7.Changes to this Privacy Policy' +
        '<br/>' +
        'a)We may revise this Privacy Policy.' +
        '<br/>' +
        'b)Without your express consent, we will not limit your rights under this privacy policy. Any changes to the privacy policy will be posted on a dedicated page.' +
        '<br/>' +
        'c)For major revisions, we will also provide more significant notice (including for some services, we will notify through website publicity, indicating the specific changes of privacy policy).' +
        '<br/>' +
        'd)Major changes referred to in this policy include but are not limited to:' +
        '<br/>' +
        '&nbsp&nbsp1.Our service model has changed dramatically. For example, the purpose of processing user information, the type of user information processed, and the way of using user information, etc ' +
        '<br/>' +
        '&nbsp&nbsp2.We have made significant changes in control, organizational structure, etc. Such as business adjustment, bankruptcy merger and acquisition, etc ' +
        '<br/>' +
        '&nbsp&nbsp3.The main objects of user information sharing, transfer or public disclosure have changed.' +
        '<br/>' +
        '&nbsp&nbsp4.Your right to participate in user information processing and the way you exercise it have changed significantly.' +
        '<br/>' +
        '&nbsp&nbsp5.When there is any change with the responsible department, contact information and complaint channel for handling user information security.' +
        '<br/>' +
        '&nbsp&nbsp6.When the user information security impact assessment report indicates that there is a high risk.'
}

export default en_US;