import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Version {

    // Version 列表
    @observable dataSource = []

    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 10,
        pageCount: 0,
        name: ''
    }

    @action setData(list, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

}

export default new Version();