import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Dashboard {
    @observable Model={
        Locations:[],
        Disconnect:0,
        Mileage:0,
        NotUse:0,
        Runtime:0,
        Run:0,
        Stop:0,
        Emergency:0,
        TotalMileage:[],
        TotalRuntime:[],
        TotalCars:[]
    }

    @action SetModel=(data)=>{
        var _model = Object.assign({},this.Model,data);
        this.Model = _model;
    }
}

export default new Dashboard()