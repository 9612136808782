import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import store from './stores/store'
import { InitStore } from './tools/tool'
import history from './components/history'
import './index.css'
import './index_mobile.css'
import 'font-awesome/css/font-awesome.min.css';
import * as serviceWorker from './serviceWorker'
import App from './App'
InitStore(store)
ReactDOM.render(<Provider store={store}><Router history={history}><App /></Router></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
