import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Role {
    // 用户列表
    @observable dataSource = []
    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 10,
        pageCount: 0,
        name: ''
    }
    @observable Menus = [];
    @observable selectedMenus = [];
    @action setData(list, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action SetMenuTrees(data) {
        this.Menus = data;
    }

    @action SetMenuTreesSub(id, data) {
        var _result = this.Menus.slice();
        var _result = _result.concat(data);
        this.Menus = _result;
    }

    @action SaveSelectedMenus = (data) => {
        this.selectedMenus = data;
    }

    @action findTreeItem = (data, id) => {
        if (data === undefined || data === null) {
            return null;
        }
        if (data.filter(x => x.id === id).length > 0) {
            return data.filter(x => x.id === id)[0];
        }
        return null;
    }
}
export default new Role();