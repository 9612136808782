import { observable, action, configure } from 'mobx'
configure({ enforceActions: 'always' });

class Trip {
    // 行程数据
    @observable dataSource = []
    @observable filter = {
        pageNumber: 1,
        totalItemCount: 0,
        pageSize: 10,
        pageCount: 0,
        plateNo: ''
    }

    @observable TripInfo = {
        //for action
        ShowVideo: false,
        ShowGeofence: false,
        ShowEvent: false,
        TripId: 0,
        driveTime: '',
        startTime: '',
        endTime: '',
        startAddress: '',
        endAddress: '',
        mileage: '',
        SumDistance: 0,
        SumTime: 0,
        TripTime: 0
    }


    // [{
    //     tripId:1,
    //     data:[]
    // }]
    //存放行程id和数据
    @observable TripGuiJiData = []

    //存放电子围栏数据
    @observable TripGeoFenceData = []

    //存放事件数据
    @observable TripEventData = []

    @observable selectedTrip=[]

    @action resetTrip = () => {
        this.TripGuiJiData = [];
        this.TripGeoFenceData = [];
        this.TripEventData = [];
        this.dataSource = [];
        this.TripInfo = {
            //for action
            ShowVideo: false,
            ShowGeofence: false,
            ShowEvent: false,
            TripId: 0,
            driveTime: '',
            startTime: '',
            endTime: '',
            startAddress: '',
            endAddress: '',
            mileage: '',
            SumDistance: 0,
            SumTime: 0,
            TripTime: 0
        };
        this.filter = {
            pageNumber: 1,
            totalItemCount: 0,
            pageSize: 10,
            pageCount: 0,
            plateNo: ''
        };
    }

    @action setData(list, dataFilter) {
        if (list !== undefined && list !== null) {
            this.dataSource = list;
        }
        if (dataFilter !== undefined && dataFilter !== null) {
            var _filter = Object.assign({}, this.filter, dataFilter);
            this.filter = _filter;
        }
    }

    @action setTripGuijiData = (tripId, data) => {
        let existed = this.TripGuiJiData.filter(x => x.tripId === tripId);
        if (existed.length > 0 && existed.data !== undefined && existed.data !== undefined) {
            if (existed.data.length === 0) {
                existed.data = data;
            }
        } else {
            this.TripGuiJiData.push({ tripId: tripId, data: data })
        }
    }

    @action setTripGeoFenceData = ( data) => {
        let existed = this.TripGeoFenceData;
        if (existed.length > 0 && existed.data !== undefined && existed.data !== undefined) {
            if (existed.data.length === 0) {
                existed.data = data;
            }
        } else {
            this.TripGeoFenceData.push({data: data })
        }
    }

    @action setTripEventData = (tripId, data) => {
        let existed = this.TripEventData.filter(x => x.tripId === tripId);
        if (existed.length > 0 && existed.data !== undefined && existed.data !== undefined) {
            if (existed.data.length === 0) {
                existed.data = data;
            }
        } else {
            this.TripEventData.push({ tripId: tripId, data: data })
        }
    }

    @action showTrip = (tripId, showOrHide) => {
        if (this.dataSource.length > 0) {
            let data = this.dataSource.slice();
            var trips = data.filter(x => x.id === tripId);
            if (trips.length > 0) {
                trips[0].checked = showOrHide === undefined ? !trips[0].checked : showOrHide
            }
            this.dataSource = data;
        }
    }

    @action showTripOnly = (tripId) => {
        let data = this.dataSource.slice();
        data.forEach(item => {
            if (item.id === tripId) {
                item.checked = true;
            } else {
                item.checked = false;
            }
        })
        this.dataSource = data;
    }

    @action setTripInfo = (data) => {
        let _trip = Object.assign({}, this.TripInfo, data);
        this.TripInfo = _trip;
    }

    @action setToSelectedTrip=(trip,isOnly)=>{
        let data = this.selectedTrip.slice();
        let _index = data.findIndex(x=>x.id===trip.id);

        if(isOnly === true){
            if(_index === -1){
                data.push(trip);
            }
        }else{
            if(_index !== -1){
                data.splice(_index,1);
            }else{
                data.push(trip);
            }
        }

        this.selectedTrip=data;
    }

    @action initAllTrip = () => {
        this.selectedTrip = [];
    }
}
export default new Trip();